const ContentManage = () => import('../pages/contentMange/appletContent/index');

export default [
    {
        path: '/appletContent', // 导游列表 老系统数据
        name: 'appletContent',
        meta: {
            title: '小程序内容管理',
            auth: true
        },
        component: ContentManage
    }
]
