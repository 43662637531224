/**
* @Author:liaoyi
* @Date:2022/4/11
*/
<style lang="scss" scoped>
</style>
<template>
    <el-tree :data="treeData" v-bind="$attrs" ref="tree" v-loading="loading"/>
</template>

<script type="text/ecmascript-6">
export default {
    name: 'ApiTreeSelect',
    //定义模版数据
    data () {
        return {
            treeData: [],
            loading: false
        }
    },
    props: {
        api: {
            type: Function,
            default: null
        },
        params: {
            type: Object,
            default: () => ({})
        },
        immediate: {
            type: Boolean,
            default: true
        },
        replaceField: {
            type: Object,
            default: () => ({})
        }
    },
    //主件被加载完成
    mounted () {
        if (this.immediate)
            this.loadData()
    },
    //定义事件方法
    methods: {
        getData () {
            return this.treeData;
        },
        async loadData () {
            this.loading = true
            const ret = await this.api(this.params)
            if (ret.success && ret.data)
                this.treeData = ret.data
            this.loading = false
        },
        setCheckedKeys (keys) {
            this.$refs['tree'].setCheckedKeys(keys)
        },
        getCheckedKeys () {
            return this.$refs['tree'].getCheckedKeys()
        }
    },
    //监听模版变量
    watch: {
        params () {
            this.loadData();
        }
    }

}
</script>
