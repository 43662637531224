/**
* Created by hansxing on 2018/8/20.
*/
<style lang="scss" scoped>
.section-login {
    .login-header {
        line-height: 30px;

        .header-title {
            float: left;
            font-family: PingFangSC-Regular;
            font-size: 24px;
            color: #666666;
        }

        .reg-forget-pwd {
            float: right;
            font-family: PingFangSC-Medium;
            font-size: 16px;
            color: #319BF7;
            padding-top: 6px;

            span {
                font-weight: 600;
                float: left;
                line-height: 20px;
                cursor: pointer;
            }

            .header-reg {
                margin-right: 15px;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    background: #CCCCCC;
                    width: 1px;
                    height: 16px;
                    right: -15px;
                    top: 50%;
                    margin-top: -8px;
                }
            }

            .header-forget-pwd {
                margin-left: 15px;
            }

        }
    }

    .login-section {
        padding-top: 30px;

        .login-phone-num {

            &.el-form-item {
                margin-bottom: 20px;
            }

            &.is-error {
                .el-input__prefix {
                    color: #f56c6c;
                }
            }

        }

        .login-password {
            &.el-form-item {
                margin-bottom: 20px;
            }

        }

        .login-remember-pwd {
            &.el-form-item {
                margin: 10px 0 30px 0;
            }
        }

        .section-footer {
            .el-button {
                font-size: 16px;
                width: 100%;
                padding: 17px 20px;
                border: 0;
                background-image: linear-gradient(90deg, #319BF7 0%, #31D1F7 100%);
            }

        }

        .manage-backstage-footer {
            p {
                font-family: PingFangSC-Regular;
                font-size: 20px;
                color: #666666;
            }

            .el-button + .el-button {
                margin-left: 0;
            }

            .el-button {
                margin-top: 15px;

                &.backstage-footer-2 {
                    background-image: linear-gradient(90deg, #25D262 0%, #66E3B7 100%);
                }

                &.backstage-footer-0 {
                    background-image: linear-gradient(90deg, #082D50 0%, #3F556B 100%);
                }

            }

        }
    }

}
</style>
<style lang="scss">
.login-section {
    .el-input__prefix, .el-input__icon, .el-input__suffix {
        transition: all .1s;
        -webkit-transition: all .1s;
    }

    .is-icon-focus {
        .el-input__prefix {
            color: #409EFF;
        }

        .el-input__suffix {
            color: #409EFF;
        }

    }

    .el-form-item__content {
        line-height: normal
    }

    .login-remember-pwd {
        .el-form-item__content {
            line-height: 20px;
        }

        .el-checkbox {
            font-weight: normal;
        }

    }

    .el-input__suffix {
        top: 1px;

        .login-pwd-type {
            &.icon-display-pwd {
                font-size: 12px;
            }

            &.icon-not-display-pwd {
                font-size: 14px;
            }

        }
    }

    .is-error {
        .el-input__prefix {
            color: #f56c6c;
        }

        .el-input__suffix {
            color: #f56c6c;
        }
    }

    .is-success {
        .el-input__inner {
            border-color: #319BF7;
        }

        .el-input__prefix {
            color: #319BF7;
        }
    }
}

</style>
<template>
    <div>
        <div class="section-login" v-loading="loading">
            <div class="login-header">
                <ul class="clear">
                    <li class="header-title">
                        登录
                    </li>
                    <li class="reg-forget-pwd clear">
                        <span class="header-reg" @click="goTo(1)">注册</span>
                        <span class="header-forget-pwd" @click="goTo(2)">忘记密码</span>
                    </li>
                </ul>
            </div>
            <div class="login-section">
                <el-form :model="form" :rules="rules" ref="loginForm" :label-width="'0px'">
                    <el-form-item prop="loginName" class="login-phone-num">
                        <el-input
                            :disabled="chooseList.length>0"
                            :class="{'is-icon-focus':isLoginNameFocus}"
                            placeholder="手机号码"
                            type="tel"
                            :maxlength="11"
                            @focus="isLoginNameFocus=true"
                            @blur="isLoginNameFocus=false"
                            prefix-icon="icon icon-phone"
                            clearable
                            v-model="form.loginName">
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="loginPwd" class="login-password">
                        <el-input
                            :disabled="chooseListINfo.length>0"
                            :type="loginPwd.type"
                            :class="{'is-icon-focus':loginPwd.isFocus}"
                            placeholder="密码"
                            @focus="loginPwd.isFocus=true"
                            @blur="loginPwd.isFocus=false"
                            prefix-icon="icon icon-password"
                            suffix-icon="icon"
                            v-model="form.loginPwd"
                            :minlength="6"
                            :maxlength="20"
                            clearable
                            auto-complete="new-password">
                            <i slot="suffix"
                               class="icon login-pwd-type"
                               @click="changePwdType"
                               :class="{'icon-display-pwd':loginPwd.type==='text',
                               'icon-not-display-pwd':loginPwd.type!='text'}">
                            </i>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="checkCode" v-if="showCheckCode">
                        <CountDownInput v-model="form.checkCode"
                                        :disabled="chooseListINfo.length>0"
                                        :api="sendCheckCodeApi"
                                        :params="{checkCodeType:8,mobile:form.loginName}"/>
                    </el-form-item>
                    <!--<el-form-item :label-width="'0px'" class="login-remember-pwd">-->
                    <!--<el-checkbox v-model="pwdChecked">记住密码</el-checkbox>-->
                    <!--</el-form-item>-->
                    <!--TDS用户登录-->
                    <div class="section-footer" v-if="chooseListINfo.length<1">
                        <el-button type="primary" @click="handleLogin()">登 录</el-button>
                    </div>
                    <!--管理后台登录-->
                    <div class="section-footer manage-backstage-footer" v-if="chooseListINfo.length>0">
                        <p>请选择登录入口：</p>
                        <el-button v-for="(vm,idx) in chooseList"
                                   :key="idx" type="primary"
                                   :class="'backstage-footer-'+vm.refType"
                                   @click="handleLogin(vm)">
                            {{
                                getUserType(vm.refType) + ':' + ((vm.userType === '0' || vm.userType === '12') ?
                                    vm.roleName : vm.companyName)
                            }}
                        </el-button>
                    </div>
                </el-form>
            </div>

        </div>
    </div>

</template>

<script type="text/ecmascript-6">
import localStorage from '@/common/js/localStorage';
import cookieStorage from '@/common/js/cookieStorage';
import {backendLogin, tdsLogin, backendGetUserInfo} from '../../service'
import Sentry from '@/common/js/sentry'
import CountDownInput from '../../../count-down/input'
import {sendCheckCodeApi} from '@/urls/index'
import moment from "moment";
export default {
    //定义模版数据
    data () {
        return {
            isLoginNameFocus: false,
            sendCheckCodeApi,
            showCheckCode: false,
            loginPwd: {
                isFocus: false,
                type: 'password'
            },
            form: {
                loginName: null,
                loginPwd: null,
                checkCode: undefined,
            },
            pwdChecked: false,
            rules: {
                loginName: [
                    {
                        validator: (rule, value, callback) => {
                            if (!value)
                                callback(new Error('请输入手机号码'));
                                // else if (!isCellphone(value))
                            //     callback(new Error('手机号格式错误'));
                            else
                                callback();
                        }, trigger: 'blur'
                    }
                ],
                loginPwd: [
                    {required: true, message: '请填写密码', trigger: 'blur'},
                    {min: 6, max: 20, message: '密码不能小于6位', trigger: 'blur'}
                ],
                checkCode: [
                    {required: true, message: '请填写验证码', trigger: 'blur'},
                ]
            },
            loading: false,
            chooseList: [],
        }
    },
    components: {CountDownInput},
    //计算属性
    computed: {
        chooseListINfo: function () {
            return this.chooseList;
        }
    },
    mounted () {
        const account = localStorage.getObject("account") || {};
        this.form.loginName = account.loginName;
        this.form.loginPwd = account.loginPwd;
    },
    //定义事件方法
    methods: {
        getUserType (type) {
            const ut = {0: "总部", 1: "供应商", 2: "运营商", 12: "总部"};
            return ut[type];
        },
        changePwdType () {
            this.loginPwd.type = this.loginPwd.type === 'text' ? 'password' : 'text';
        },
        handleLogin (choose) {
            this.$refs["loginForm"].validate(async (valid) => {
                if (valid) {
                    this.loading = true;
                    try {
                        //判断今日是否做过验证码校验
                        const sendLoginCodeList = localStorage.getObject('sendLoginCodeList') || [];
                        if (this.showCheckCode === false && (sendLoginCodeList.length === 0 ||
                            sendLoginCodeList.every(({mobile}) => mobile !== this.form.loginName) ||
                            sendLoginCodeList.find(({mobile}) => mobile === this.form.loginName).day
                            !== moment().format('YYYY-MM-DD'))) {
                            this.loading = false;
                            return this.showCheckCode = true;
                        }
                        let ret = null;
                        if (choose)
                            this.form.loginUsertypeList = [choose];
                        else
                            delete this.form.loginUsertypeList;
                        switch (this.projectName) {
                            case 'bms':
                                ret = await backendLogin(this, this.form);
                                break;
                            case 'tds':
                                ret = await tdsLogin(this, this.form);
                                break;
                        }
                        if (ret.success) {
                            //选择角色
                            if (ret.data.chooseList.length > 0) {
                                this.chooseList = ret.data.chooseList;
                                this.loading = false;
                                return false;
                            }
                            this.$store.dispatch("CHANGE_USER_LOGIN_INFO", ret.data);
                            this.cookieStorage.set("eTrip_token", ret.data.token, 2);
                            this.localStorage.setObject("loginUserInfo", ret.data);
                            this.$store.dispatch("CHANGE_LOGIN_OR_REGISTER", {
                                type: 0,
                                show: false
                            });
                            //记住账户密码
                            if (this.pwdChecked)
                                localStorage.setObject("account", this.form)
                            else
                                localStorage.setObject("account", {
                                    loginName: this.form.loginName
                                })
                            this.chooseList = [];
                            this.form = {
                                ...this.form,
                                loginPwd: null,
                                checkCode: undefined
                            }
                            this.$message.success('登录成功!');
                            if (this.showCheckCode) {
                                const index = sendLoginCodeList.findIndex(({mobile}) => mobile === this.form.loginName);
                                if (index !== -1)
                                    sendLoginCodeList[index].day = moment().format('YYYY-MM-DD');
                                else sendLoginCodeList.push({
                                    mobile: this.form.loginName,
                                    day: moment().format('YYYY-MM-DD')
                                })
                                localStorage.setObject('sendLoginCodeList', sendLoginCodeList)
                            }
                            this.showCheckCode = false;
                            const nextPath = this.sessionStorage.get("nextPath");
                            if (nextPath) {
                                this.sessionStorage.removeItem("nextPath");
                                this.$router.replace(nextPath)
                            }
                            if (this.projectName === 'tds' && ret.data.isFinancialAutonomy === 1) {
                                if (ret.data.accountBalance < 300) {
                                    this.$confirm('账户余额小于300，请及时充值！', '提示', {
                                        confirmButtonText: '确定',
                                        cancelButtonText: '取消',
                                        type: 'warning'
                                    }).then(() => {
                                        window.open('#/zslRecharge');
                                    })
                                }
                            }  // tds-zsl 充值余额
                        } else if (ret.errors && ret.errors.length > 0 &&
                            ret.errors[0].code === 'loginuserPwdErr')
                            this.$message.error('用户名和密码不匹配!')
                        else if (ret.errors && ret.errors.length > 0 &&
                            ret.errors[0].code === 'loginuserNotExist')
                            this.$message.error('登录用户不存在!')
                        else if (ret.errors[0].message)
                            this.$message.error(ret.errors[0].message)
                        else
                            this.$message.error('抱歉，登录失败!')
                        this.loading = false;
                        Sentry.setUser({
                            id: ret.data.loginuserDto.mobile
                        })
                    } catch (error) {
                        this.loading = false;
                    }
                }
            })
        },
        async getUserInfo () {
            let ret = await backendGetUserInfo(this);
            if (ret.success) {
                this.$store.dispatch("CHANGE_USER_INFO", ret.data);
                cookieStorage.setObject("userInfo", ret.data);

            } else if (ret.errors && ret.errors.lenght > 0)
                this.$message.error(ret.errors[0].message)
            return;
        },
        goTo (type) {
            this.$store.dispatch("CHANGE_LOGIN_OR_REGISTER", {
                show: true,
                type: type
            })
        }

    },

}
</script>

