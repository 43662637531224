/**
* Created by hansxing on 2018/8/21.
*/
<style lang="scss" scoped>
    .section-register {
        .register-header {
            line-height: 30px;
            .header-return {
                cursor: pointer;
                margin-bottom: 10px;
                i {
                    font-size: 28px;
                }
            }
            .header-title {
                float: left;
                font-family: PingFangSC-Regular;
                font-size: 24px;
                color: #666666;
            }
            .title-step {
                font-size: 14px;
                color: #666666;
                display: inline-block;
                vertical-align: middle;
                margin-top: -3px;
            }
            .reg-forget-pwd {
                float: right;
                font-family: PingFangSC-Medium;
                font-size: 16px;
                color: #319BF7;
                .header-login {
                    font-weight: 600;
                    line-height: 20px;
                    cursor: pointer;
                }

            }
        }
        .register-section {
            padding-top: 30px;
            .register-step-one {
                .step-one-phone {
                    &.el-form-item {
                        margin-bottom: 20px;
                    }
                }
                .step-one-writing {
                    font-size: 14px;
                    color: #999999;
                    span {
                        color: #319BF7;
                    }

                }
            }

            .section-footer {
                margin-top: 40px;
                .el-button {
                    font-size: 16px;
                    width: 100%;
                    padding: 17px 20px;
                    border: 0;
                    background-image: linear-gradient(90deg, #319BF7 0%, #31D1F7 100%);
                    margin-top: 15px;
                    &.backstage-footer-supplier {
                        background-image: linear-gradient(90deg, #25D262 0%, #66E3B7 100%);
                    }
                }
                .footer-confirm-reg {
                    font-family: PingFangSC-Regular;
                    font-size: 20px;
                    color: #666666;
                }
                .el-button + .el-button {
                    margin-left: 0;
                }
                .el-button {
                    margin-top: 15px;
                    &.backstage-footer-2 {
                        background-image: linear-gradient(90deg, #25D262 0%, #66E3B7 100%);
                    }
                    &.backstage-footer-0 {
                        background-image: linear-gradient(90deg, #082D50 0%, #3F556B 100%);
                    }

                }

            }
        }

    }
</style>
<style lang="scss">
    .register-section {
        .el-input__prefix, .el-input__icon, .el-input__suffix {
            transition: all .1s;
            -webkit-transition: all .1s;
        }
        .is-icon-focus {
            .el-input__prefix {
                color: #409EFF;
            }
            .el-input__suffix {
                color: #409EFF;
            }
        }
        .register-step-one {
            .step-one-phone {
                &.is-error {
                    .el-input__prefix {
                        color: #f56c6c;
                    }
                }
                &.is-success {
                    .el-input__inner {
                        border-color: #319BF7;
                    }
                    .el-input__prefix {
                        color: #319BF7;
                    }
                }
            }
        }
        .register-step-three {
            .step-three-name, .step-three-pwd {
                &.is-error {
                    .el-input__prefix {
                        color: #f56c6c;
                    }
                }
                &.is-success {
                    .el-input__inner {
                        border-color: #319BF7;
                    }
                    .el-input__prefix {
                        color: #319BF7;
                    }
                }
            }
        }

        .step-three-pwd {
            .el-input__suffix {
                .register-pwd-type {
                    &.icon-display-pwd {
                        font-size: 12px;
                    }
                    &.icon-not-display-pwd {
                        font-size: 14px;
                    }
                }
            }

        }
        // 第二步

        // 第三步
        .register-step-three {

        }
        // 注册信息提交成功
        .register-sub-success {
            text-align: center;
            i {
                font-size: 70px;
                color: #319BF7;
            }
            h4 {
                font-size: 24px;
                color: #319BF7;
                margin-top: 25px;
            }
            p {
                font-size: 16px;
                margin-top: 15px;
            }

        }

    }

</style>
<template>

    <div>
        <div class="section-register">
            <div class="register-header" v-if="tabIndex!=4">
                <div class="header-return" v-if="tabIndex===3" @click="tabIndex=2">
                    <i class="icon icon-left-return"></i>
                </div>
                <ul class="clear">
                    <li class="header-title">
                        注册
                        <span class="title-step" v-if="tabIndex===0||tabIndex===1">第1步：填写公司管理员手机号</span>
                        <span class="title-step" v-if="tabIndex===2">第2步：验证手机号</span>
                        <span class="title-step" v-if="tabIndex===3">
                            第3步：{{passWord.show ? '设置用户名和密码' : '设置用户名'}}
                        </span>

                    </li>
                    <li class="reg-forget-pwd">
                        <span class="header-login" @click="goToLogin">登录</span>
                    </li>
                </ul>
            </div>
            <div class="register-section" v-loading="loading">
                <el-form :model="form" :rules="rules" ref="registerForm">
                    <!--注册第一步  Begin-->
                    <div class="register-step-one" v-if="tabIndex===0||tabIndex===1">
                        <el-form-item prop="adminMobile"
                                      :label-width="'0px'"
                                      class="step-one-phone">
                            <el-input :class="{'is-icon-focus':isPhoneFocus}"
                                      placeholder="公司管理员手机号码"
                                      maxlength="11"
                                      @focus="isPhoneFocus=true"
                                      @blur="isPhoneFocus=false"
                                      prefix-icon="icon icon-phone"
                                      v-model="form.adminMobile"
                                      auto-complete="off">
                            </el-input>
                        </el-form-item>
                        <div class="step-one-writing">
                            请联系贵公司管理员，获取TA的手机号码。<br/>
                            若有疑问，请询客服：
                            <span>400-821-0189</span>
                        </div>
                    </div>
                    <!--注册第二步  -->
                    <verification-code :defaultMobile="form.mobile"
                                       :defaultCode="form.checkCode"
                                       :extra="verificationCodeExtra"
                                       ref="verificationCode" v-if="tabIndex===2">
                    </verification-code>
                    <!--第三步-->
                    <div class="register-step-three" v-if="tabIndex===3">
                        <el-form-item prop="loginName" :label-width="'0px'" class="step-three-name">
                            <el-input
                                    :class="{'is-icon-focus':isNameFocus}"
                                    placeholder="真实姓名"
                                    @focus="isNameFocus=true"
                                    @blur="isNameFocus=false"
                                    maxlength="20"
                                    prefix-icon="icon icon-user-two"
                                    v-model.trim="form.loginName"
                                    auto-complete="off">
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="passWord" v-if="passWord.show" :label-width="'0px'" class="step-three-pwd">
                            <el-input
                                    :type="passWord.type"
                                    :class="{'is-icon-focus':isPwdFocus}"
                                    placeholder="密码由6位以上数字、字母组合"
                                    @focus="isPwdFocus=true"
                                    @blur="isPwdFocus=false"
                                    prefix-icon="icon icon-password"
                                    suffix-icon="icon"
                                    minlength="6"
                                    maxlength="20"
                                    v-model="form.passWord"
                                    auto-complete="off">

                                <i slot="suffix" class="icon register-pwd-type"
                                   @click="passWord.type=passWord.type==='text'?'password':'text'"
                                   :class="{'icon-display-pwd':passWord.type==='text',
                                   'icon-not-display-pwd':!passWord.type==='password'}">
                                </i>
                            </el-input>
                        </el-form-item>
                    </div>

                    <div class="register-sub-success" v-if="tabIndex===4">
                        <i class="icon icon-success-state"></i>
                        <h4>注册信息提交成功</h4>
                        <p>您公司的管理员将处理注册审核，<br/>
                            审核结果将以短信方式通知，请注意查收。</p>
                    </div>


                    <!--管理后台登录-->
                    <div class="section-footer">
                        <el-button type="primary" v-if="tabIndex===0||tabIndex===2||tabIndex===3" @click="handleNext">
                            下一步
                        </el-button>

                        <!--第一步的下一步-->
                        <div v-if="tabIndex===1">
                            <p class="footer-confirm-reg">
                                {{loginUserAdminRspDtoList.length > 1 ? '请选择并确认注册入口' : '请确认注册入口'}}：
                            </p>
                            <el-button v-for="(vm,idx) in loginUserAdminRspDtoList"
                                       :key="idx" type="primary"
                                       :class="'backstage-footer-'+vm.refType"
                                       @click="handleNext(vm)">
                                {{getUserType(vm.refType) + ':' + vm.companyName}}
                            </el-button>
                        </div>

                        <el-button type="primary" v-if="tabIndex===4" @click="handleNext">知道了</el-button>

                    </div>
                </el-form>
            </div>
        </div>
    </div>

</template>

<script type="text/ecmascript-6">
    import verificationCode from '../verificationCode/index.vue'
    import {
        tdsUserIsRegister,
        addRegister,
        supplierOrOperatorAdminIsExists,
        checkVerifyCode
    } from '../../service'
    import {isRealName, isCellphone} from '@/common/js/Utils'

    export default {
        //定义模版数据
        data() {
            return {
                tabIndex: 0,
                isPhoneFocus: false,
                isNameFocus: false,
                isPwdFocus: false,
                passWord: {
                    isPwdFocus: false,
                    type: 'password',
                    show: true
                },
                form: this.sessionStorage.getObject('register_form_info') || {
                    adminMobile: '',
                    mobile: '',
                    checkCode: '',
                    loginName: '',
                    passWord: ''
                },
                rules: {
                    adminMobile: [
                        {
                            validator: (rule, value, callback) => {
                                if (!value)
                                    callback(new Error('请输入公司管理员手机号码'));
                                else if (!isCellphone(value))
                                    callback(new Error('手机号格式错误'));
                                else
                                    callback();
                            }, trigger: 'blur'
                        }
                    ],
                    passWord: [
                        {required: true, message: '请填写密码', trigger: 'blur'},
                        {min: 6, max: 20, message: '密码由6位以上数字、字母组合', trigger: 'blur'}
                    ],
                    loginName: [
                        {
                            validator: (rule, value, callback) => {
                                if (!value) {
                                    callback(new Error('请填写真实姓名'));
                                } else if (!isRealName(value)) {
                                    callback(new Error('真实姓名格式错误'));
                                } else {
                                    callback();
                                }
                            }, trigger: 'blur'
                        }
                    ]
                },
                registerUserInfo: {},
                adminOrgInfo: {},
                loginUserAdminRspDtoList: [],
                loading: false,
                verificationCodeExtra: {}
            }
        },
        components: {
            verificationCode
        },
        //计算属性
        computed: {
        },
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
            getUserType(type) {
                const ut = ["总部", "供应商", "运营商"]
                return ut[type];
            },
            async handleNext(obj) {
                this.sessionStorage.setObject('register_form_info', this.form);
                switch (this.tabIndex) {
                    case 0:
                        await this.querybmsistratorMobile();
                        break;
                    case 1:
                        this.adminOrgInfo = obj;
                        this.verificationCodeExtra = obj
                        this.tabIndex += 1
                        break;
                    case 2:
                        this.$refs["verificationCode"].getFormData(async (form, extra) => {
                            if (form) {
                                this.loading = true;
                                this.form.mobile = form.mobile;
                                this.form.checkCode = form.code
                                if (extra) {
                                    this.registerUserInfo = extra
                                    this.form.loginName = extra.loginName
                                    this.passWord.show = false;
                                }
                                //校验验证码
                                const _form = {
                                    mobile: form.mobile,
                                    checkCode: form.code,
                                    refType: this.adminOrgInfo.refType
                                }
                                let ret = await checkVerifyCode(this, _form);
                                if (ret.success) {
                                    if (ret.data.checkCode == 1)
                                        this.tabIndex += 1;
                                    else
                                        this.$message.error("验证码错误")
                                }
                                else
                                    this.$message.error(ret.errors[0].message)
                                this.loading = false;
                            }
                        })
                        break;
                    case 3:
                        this.$refs["registerForm"].validate(async (valid) => {
                            if (valid) {
                                await  this.register()
                            }
                        })

                        break;
                    default:
                }
            },
            async register() {
                this.loading = true;
                let ret = null;
                let form = JSON.parse(JSON.stringify(this.form))
                form.isRegister = 0
                if (this.registerUserInfo.id) {
                    form.isRegister = 1
                    form.loginuserId = this.registerUserInfo.id
                    delete form.passWord
                }

                form.orginfoId = this.adminOrgInfo.orginfoId
                form.refId = this.adminOrgInfo.refId
                form.refType = this.adminOrgInfo.refType
                form.companyName = this.adminOrgInfo.companyName

                ret = await addRegister(this, form);
                if (ret.success) {
                    if (ret.data.checkCode == 0) {
                        return this.$message.error('验证码错误！')
                    }
                    if (ret.data.isRegister == 1) {
                        this.sessionStorage.removeItem('register_form_info')
                        this.$message.success('注册成功！')
                        this.localStorage.setObject('account', {
                            loginName: this.form.mobile
                        })
                        this.$store.dispatch("CHANGE_LOGIN_OR_REGISTER", {
                            show: true,
                            type: 0
                        })

                    }
                } else {
                    this.$message.error(ret.errors[0].message)
                }
                this.loading = false;
            },
            querybmsistratorMobile() {
                let ret = null;
                this.$refs["registerForm"].validate(async (valid) => {
                    if (valid) {
                        this.loading = true;
                        switch (this.projectName) {
                            case 'bms':
                                ret = await supplierOrOperatorAdminIsExists(this, this.form.adminMobile);
                                if (ret.success)
                                    if (ret.data.isExists == 1) {
                                        this.loginUserAdminRspDtoList = ret.data.loginuserAdminRspDtoList;
                                        this.tabIndex += 1;
                                    } else
                                        this.$message.warning('该手机号未绑定公司')
                                else
                                    this.$message.error("抱歉，服务繁忙!")
                                break;
                            case 'tds':
                                ret = await  tdsUserIsRegister(this, this.form.adminMobile);
                                if (ret.success)
                                    if (ret.data.isExists == 1) {
                                        this.adminOrgInfo = ret.data.loginuserAdminRspDtoList[0];
                                        this.tabIndex += 2;
                                    } else
                                        this.$message.warning('该手机号未绑定公司')
                                else
                                    this.$message.error("抱歉，服务繁忙!")
                                break;
                        }
                        this.loading = false;
                    }
                })

            },
            goToLogin() {
                this.$store.dispatch("CHANGE_LOGIN_OR_REGISTER", {
                    show: true,
                    type: 0
                })
            }

        },
        //监听模版变量
        watch: {}

    }
</script>

