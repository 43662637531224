import service from '../../urls/quotationUrls'
import Loading from '@/common/js/loading'

const state = () => ({
    // addServiceTime: {}, // 新增客服时间
    serviceTimeList: [], // 客服时间列表
    serviceTimeDetail: {}, // 编辑客服时间
    updateServiceTime: {}, // 更新修改客服时间
    serviceTimeShow: false, // 客服时间弹窗
    orderContactList: [],  // 订单联系人列表
    updateOrderContact: {},  // 更新订单联系人
    orderContactShow: false,  // 订单联系人弹窗
    answerContactShow: false, // 应单联系人弹窗
    updateAnswerContact: {},  // 更新应单人
    answerContactList: {},  // 应单人列表
    initCarBrandModel: [],  // 编辑时初始化车辆品牌型号
    isSingleSettingShow: false, // 是否是应单基础设置
});

const mutations = {
    // addServiceTime(state, arr) {
    //     state.addServiceTime = arr
    // }, // 新增客服时间
    serviceTimeList(state, arr) {
        state.serviceTimeList = arr
    },  // 客服时间列表
    serviceTimeDetail(state, arr) {
        state.serviceTimeDetail = arr
    },  // 编辑客服时间
    updateServiceTime(state, arr) {
        state.updateServiceTime = arr
    },  // 更新修改客服时间
    serviceTimeShow(state, arr) {
        state.serviceTimeShow = arr
    },  // 客服时间弹窗
    orderContactList(state, arr) {
        state.orderContactList = arr
    },  // 订单联系人列表
    updateOrderContact(state, arr) {
        state.updateOrderContact = arr
    },  // 更新订单联系人
    orderContactShow(state, arr) {
        state.orderContactShow = arr
    },  // 订单联系人弹窗
    answerContactShow(state, arr) {
        state.answerContactShow = arr
    },  // 应单人弹窗
    updateAnswerContact(state, arr) {
        state.updateAnswerContact = arr
    },  // 更新应单人
    answerContactList(state, arr) {
        state.answerContactList = arr
    },  // 应单人列表
    initCarBrandModel(state, arr) {
        state.initCarBrandModel = arr
    },  // 编辑时初始化车辆品牌型号
    isSingleSettingShow(state, arr) {
        state.isSingleSettingShow = arr
    },  // 是否是应单基础设置

};

const actions = {
    // addServiceTime: async (store, form) => {
    //     Loading.show()
    //     let res = await service.insertProductQuoteCustomService(form);
    //     Loading.hide()
    //     return res
    // },
    serviceTimeList: async (store, form) => {
        Loading.show()
        let res = await service.productQuoteCustomServicePageList(form);
        if (res.success && res.data) {
            Loading.hide()
            return res.data;
        }
    },
    serviceTimeDetail: async (store, id) => {
        let res = await service.productQuoteCustomServiceDetail(id);
        if (res.success && res.data) {
            store.commit('serviceTimeDetail', res.data)
        }
    }, // 暂时没有用
    updateServiceTime: async (store, form) => {
        Loading.show()
        let res = await service.updateProductQuoteCustomService(form);
        Loading.hide()
        return res
    },
    serviceTimeShow: ({commit}, bool) => {
        commit('serviceTimeShow', bool)
    },
    orderContactList: async (store, form) => {
        Loading.show()
        let res = await service.productOrderConnectInfoPageList(form);
        if (res.success && res.data) {
            Loading.hide()
            return res.data
        }

    },
    updateOrderContact: async (store, form) => {
        Loading.show()
        let res = await service.updateProductOrderConnectInfo(form);
        Loading.hide()
        return res
    },
    orderContactShow: ({commit}, bool) => {
        commit('orderContactShow', bool)
    },
    answerContactShow: ({commit}, bool) => {
        commit('answerContactShow', bool)
    },
    updateAnswerContact: async (store, form) => {
        Loading.show()
        let res = await service.updateProductReceiverInfo(form);
        Loading.hide()
        return res
    },
    answerContactList: async (store, form) => {
        Loading.show()
        let res = await service.productReceiverInfoPageList(form);
        if (res.success && res.data) {
            Loading.hide()
            return res.data
        }
    },
    initCarBrandModel: ({commit}, bool) => {
        commit('initCarBrandModel', bool)
    },
    isSingleSettingShow: ({commit}, bool) => {
        commit('isSingleSettingShow', bool)
    },
    //
};

export default {
    state,
    mutations,
    actions
}

