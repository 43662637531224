<style lang="scss">
    .forget-password {
        .el-dialog__wrapper {

            .el-dialog{
                z-index: 9;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                position: absolute;
                margin: 0 auto !important;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        }

        .el-dialog {
            width: 410px;
        }
        .forget-title {
            font-size: 24px;
            color: #666666;
            padding-left: 20px;
            padding-top: 10px;
        }
        .user-name {
            font-size: 18px;
            color: #666666;
        }
        .forget-input {
            .el-input__prefix, .el-input__icon, .el-input__suffix {
                transition: all .1s;
                -webkit-transition: all .1s;
            }
            width: 330px;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            margin-left: 20px;
            &.is-error {
                .el-input__prefix {
                    color: #f56c6c;
                }
            }
            &.is-success, &.is-validating {
                .el-input__inner {
                    border-color: #319BF7;
                }
                .el-input__prefix {
                    color: #319BF7;
                }
            }
        }
        .el-input__prefix {
            i {
                font-size: 22px;
            }
            color: #999999;;
        }
        .forget-btn {
            width: 330px;
            margin-left: 20px;
            font-size: 16px
        }
        .is-icon-focus {
            .el-input__prefix {
                color: #409EFF;
            }
        }

    }
</style>
<template>

    <div class="forget-password">
        <el-dialog title="" :visible.sync="showVisible"
                   :close-on-click-modal="false"
                   :before-close="closeDialog">
            <div slot="title" class="forget-title">修改登录密码</div>
            <el-form :model="forgetForm" :rules="ruleForm" ref="rule">
                <el-form-item class="forget-input">
                    <p class="user-name">用户名：{{userInfo.loginName}}</p>
                </el-form-item>
                <el-form-item class="forget-input" prop="oldPassword" style="position: relative">
                    <el-input prefix-icon="icon icon-passwd" v-model="forgetForm.oldPassword"
                              :class="{'is-icon-focus':updatePwd.focus}"
                              @focus="updatePwd.focus=true"
                              @blur="updatePwd.focus=false"
                              placeholder="旧密码" type="text">
                        <!--<i slot="suffix" class="icon" @click="showOldPwd=!showOldPwd"-->
                        <!--:class="{'icon-in_zhengyan_fill':showOldPwd, 'icon-in_biyan_fill':!showOldPwd}"></i>-->
                    </el-input>
                </el-form-item>
                <el-form-item prop="newPassword" class="forget-input">
                    <el-input prefix-icon="icon icon-passwd" v-model="forgetForm.newPassword"
                              :class="{'is-icon-focus':updatePwd.focusNew}"
                              @focus="updatePwd.focusNew=true"
                              @blur="updatePwd.focusNew=false"
                              placeholder="新密码由数字、字母组合，不少于6位" type="text" maxlength="20">
                        <!--<i slot="suffix" class="icon" @click="showNewPwd=!showNewPwd"-->
                        <!--:class="{'icon-in_zhengyan_fill':showNewPwd, 'icon-in_biyan_fill':!showNewPwd}"></i>-->
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="forget-btn" type="primary" @click="updatePassword">修改密码</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>

</template>

<script type="text/ecmascript-6">
    import {verifyPwd} from '@/common/js/Utils';

    export default {
        //定义模版数据
        data() {

            let validateOldPass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入旧密码'));
                } else {
                    if (this.forgetForm.newPassword !== '') {
                        this.$refs.rule.validateField('newPassword');
                    }
                    callback();
                }
            };
            let validateNewPwd = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入新密码'));
                } else if (value == this.forgetForm.oldPassword) {
                    callback(new Error('新密码不能与旧密码相同!'));
                } else if (!verifyPwd(value)) {
                    callback(new Error('密码格式错误'));
                } else {
                    callback();
                }

            };
            let userInfo = {};
            let info = this.getUserInfo() || {};
            info.loginuserDto && (userInfo = info.loginuserDto);
            return {
                updatePwd: {
                    focus: false,
                    focusNew: false
                },
                forgetForm: {
                    oldPassword: '',
                    newPassword: ''
                },
                ruleForm: {
                    oldPassword: [{
                        validator: validateOldPass,
                        trigger: 'blur'
                    }],
                    newPassword: [{
                        validator: validateNewPwd,
                        trigger: 'blur'
                    }],
                },

                showOldPwd: true,
                showNewPwd: true,
                userInfo: userInfo
            }
        },
        props: {
            showVisible: {
                type: Boolean,
                default: false
            }
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
            closeDialog() {
                this.$emit('update:close')
            },
            updatePassword() {
                this.$refs.rule.validate((valid) => {
                    if (valid)
                        this.$emit('update:password', this.forgetForm);
                })
            }
        },
        //监听模版变量
        watch: {}

    }
</script>
