/**
 * Created by liaoyi on 2017/6/26.
 */

import Vue from 'vue'
import {debounce} from './Utils'
// 图片预加载器
Vue.directive('imgLoader', {
    bind: function (el, binding) {
        // el.style.backgroundImage = 'url('+da.defaultImg+')';
        var img = new Image();
        img.onload = function () {
            el.style.backgroundImage = 'url(' + img.src + ')';
        }
        img.onerror = function () {

        }
        img.src = encodeURI(binding.value);
    }
})

Vue.directive('ellipsis', {
    inserted(el, binding) {
        const {value} = binding;
        value.rows = value.rows || 2;
        value.lineHeight = value.lineHeight || 20;
        value.btnShowText = value.btnShowText || '...展开';
        value.btnHideText = value.btnHideText || '收起';
        const height = el.offsetHeight;
        const maxHeight = value.rows * value.lineHeight;
        if (height > maxHeight) {
            el.style.overflow = 'hidden';
            el.style.maxHeight = maxHeight + 'px';
            const btn = document.createElement('div');
            btn.className = 'color-primary';
            btn.style.backgroundColor = '#fff';
            btn.style.position = 'absolute'
            btn.style.bottom = 0;
            btn.style.lineHeight = value.lineHeight + 'px'
            btn.style.right = 0;
            btn.innerText = value.btnShowText;
            btn.style.padding='0px 5px'
            btn.addEventListener('click', () => {
                if (btn.innerText === value.btnShowText) {
                    btn.innerText = value.btnHideText;
                    el.style.maxHeight = height + 'px';
                } else {
                    el.style.maxHeight = maxHeight + 'px';
                    btn.innerText = value.btnShowText;
                }
            });
            el.style.position = 'relative';
            el.appendChild(btn);
        }
    }
});

export default {
    install() {
        //防抖
        Vue.directive('debounce', {
            bind: function (el, binding) {
                let debounceFun = debounce(binding.value)
                el.addEventListener('click', debounceFun)
            }
        })
    }
}
