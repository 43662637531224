const member = () => import('../pages/member/');

export default [
    {
        path: '/member', // 订单管理
        name: 'member',
        meta: {
            title: '订单列表',
            auth: true
        },
        component: member
    }
]