const trackManage = () => import('../pages/track/')
const capacityMonitor = () => import('../pages/capacityMonitor/')

export default [
    {
        path: '/trackManage',
        name: 'trackManage',
        meta: {
            title: '轨迹',
            free: true,
            outside: true
        },
        component: trackManage
    },

    {
        path: '/capacityMonitor',
        name: 'capacityMonitor',
        meta: {
            title: '运力监控',
            auth:true
        },
        component: capacityMonitor
    },
]
