import http from '@/common/js/http'
import sessionStorage from '@/common/js/sessionStorage'
import localStorage from '@/common/js/localStorage'

/**
 * 省市接口
 * grade 等级：1省2市3区县
 */
export const getCityData = async (_this, grade, parentCode) => {
    let url = '/galaxyCityTds/doCityList';
    if (process.env_project.MODULE_ENV === "bms") url = '/galaxyCity/list';
    const cityData = _this.sessionStorage.getObject('cityList') || null;
    if (cityData && grade == 2 && !parentCode) return cityData;
    const ret = await _this.http(url, {grade: grade, pageSize: 4000, parentCode: parentCode}, 'POST');
    if (ret.success && ret.data && ret.data.data) {
        if (grade == 2 && !parentCode) _this.sessionStorage.setObject('cityList', ret.data.data);
        return ret.data.data;
    } else
        return false;
};
/**
 * 获取级联城市数据
 */
export const getCascaderCityData = async () => {
    let url = '/galaxyCity/hierarchyCityInfo';
    const cityData = sessionStorage.getObject('CascaderCityData') || null;
    if (cityData) return cityData;
    const ret = await http(url);
    if (ret.success && ret.data) {
        sessionStorage.setObject('CascaderCityData', ret.data);
        return ret.data;
    } else
        return [];
};

/**
 * 根据供应商id获取运营商信息
 */
export const getSupplierIdOperatorInfo = async (id) => {
    let url = `/galaxyOperator/getBySupplierId/${id}`;
    let userInfo = localStorage.getObject("loginUserInfo");
    if (userInfo.operatorId) return userInfo;
    const res = await http(url);
    if (res.success && res.data) {
        let operatorInfo = {
            operatorId: res.data.operatorId,
            operatorName: res.data.operatorName
        }
        return operatorInfo
    }
}

/**
 * 根据城市id获取供应商
 */
export const getSupplierByCityId = async (data) => {
    return http('/galaxySupplier/getSupplierListByCityId', data, 'POST')
}

/**
 * 根据运营商ID获取运营商城市
 */
export const getOperatorServerCityByOperatorId = (operatorId) => http(`/galaxyOperator/doGalaxyGetOperatorServerCity/${operatorId}`)

/**
 * 运营商列表
 * **/
export const getOperatorList = async () => {
    const res = await http('/galaxyOperator/operatorInfoList', {}, "POST");
    if (res.success && res.data) {
        let operatorArr = res.data.map(item => {
            let obj = {}
            obj.value = item.id
            obj.label = item.operatorName
            return obj
        })
        return operatorArr
    }
}

/**
 * 供应商列表
 * **/
export const getSupplierList = async () => {
    const res = await http('/galaxySupplier/doGalaxyGetSupplierList', {}, 'POST');
    if (res.success && res.data) {
        let supplierArr = res.data.map(item => {
            let obj = {}
            obj.value = item.id
            obj.label = item.supplierName
            return obj
        })
        return supplierArr
    }
}
/**
 * 根据运营商id 查询供应商列表
 * **/
export const getOperaIdSupplierList = async (id) => {
    const res = await http('/galaxySupplier/doGalaxyGetSupplierInfoByOperatorId', {operatorId: id}, 'POST');
    if (res.success && res.data) {
        let supplierArr = res.data.map(item => {
            let obj = {}
            obj.value = item.id
            obj.label = item.supplierName
            return obj
        })
        return supplierArr
    }
}

/**
 * 机构列表 运营商和供应商合并
 * **/
export const getOrganizationList = async () => {
    const operatorList = await getOperatorList()
    const supplierList = await getSupplierList()
    let list = []
    if (operatorList || supplierList) {
        let operatorArr = operatorList.map(item => {
            let obj = {}
            obj.value = item.id
            obj.label = item.operatorName
            return obj
        })
        let supplierArr = supplierList.map(item => {
            let obj = {}
            obj.value = item.id
            obj.label = item.supplierName
            return obj
        })
        list = [...operatorArr, ...supplierArr]
        return list
    }
}

/**
 * 发送验证码
 * @params data={checkCodeType:number mobile:number}
 * checkCodeType: 8//登录
 */
export const sendCheckCodeApi = (data) => {
    return http('/commonBms/sendCheckCode', data, 'POST')
}

/**
 * 获取车辆品牌型号
 */

export const getCarBrandModelListApi = (data = {}) => http('/galaxyStationApi/doCarBrandModelList', data, 'POST')
