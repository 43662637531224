const guiderList = () => import('../pages/guider/listOld/');


export default [
    {
        path: '/guiderList', // 导游列表 老系统数据
        name: 'guiderList',
        meta: {
            title: '导游列表',
            auth: true
        },
        component: guiderList
    }
]
