const MessageList = () => import('../pages/messageMange/list/index');
const MessageAdd = () => import('../pages/messageMange/add/index');


export default [
    {
        path: '/messageList',
        name: 'messageList',
        meta: {
            title: '历史消息记录',
            auth: true
        },
        component: MessageList
    },
    {
        path: '/messageAdd',
        name: 'messageAdd',
        meta: {
            title: '发布消息',
            auth: true
        },
        component: MessageAdd
    }
]
