import service from '../../urls/settlementtUrls'

const state = () => ({
    settleList: [],  // 结算记录列表
    addSettleRecord: [],  // 新增结算记录
    settleInfo: {},  // 结算详情
    driverInfoList: [],  // 新增结算明细-添加司机
    delPaySettle: {},  // 删除结算信息 列表
    settleState: {},    // 结算状态修改为已结算
    createSettle: {},  // 保存
    driverSettleList: [],
    delDriverSettle: {},
    editDriverSettle: {},
    delPaySettleDetail: {},
    settleListStateNum: {},
    exportDriverSettle: {},  // 导出司机结算信息
    exportPaySettle: {},
});


const mutations = {
    settleList(state, arr) {
        state.settleList = arr
    },  // 结算记录列表
    addSettleRecord(state, arr) {
        state.addSettleRecord = arr
    },  // 新增结算记录
    settleInfo(state, arr) {
        state.settleInfo = arr
    },  // 结算详情
    driverInfoList(state, arr) {
        state.driverInfoList = arr
    },    // 新增结算明细-添加司机
    delPaySettle(state, arr) {
        state.delPaySettle = arr
    },  //删除结算信息 列表
    settleState(state, arr) {
        state.settleState = arr
    },  // 结算状态修改为已结算
    createSettle(state, arr) {
        state.createSettle = arr
    }, // 保存
    driverSettleList(state, arr) {
        state.driverSettleList = arr
    },
    delDriverSettle(state, arr) {
        state.delDriverSettle = arr
    },
    editDriverSettle(state, arr) {
        state.editDriverSettle = arr
    },
    delPaySettleDetail(state, arr) {
        state.delPaySettleDetail = arr
    },
    settleListStateNum(state, arr) {
        state.settleListStateNum = arr
    },
    exportDriverSettle(state, arr) {
        state.exportDriverSettle = arr
    },
    exportPaySettle(state, arr) {
        state.exportPaySettle = arr
    },
};

const actions = {
    settleList: async (store, data) => {
        data._this.loadingShow()
        let res = await service.paySettlementPageList(data._this, data.form)
        if (res.success && res.data) {
            store.commit('settleList', res.data)
        }
        data._this.loadingHide()
    },  // 结算记录列表
    addSettleRecord: async (store, data) => {
        data._this.loadingShow()
        let res = await service.createPaySettlementOnly(data._this, data.form)
        if (res.success && res.data) {
            data._this.loadingHide()
            return true
        }
    },  // 新增结算记录
    settleInfo: async (store, data) => {
        data._this.loadingShow()
        let res = await service.paySettlementDetail(data._this, data.id);
        if (res.success && res.data) {
            store.commit('settleInfo', res.data)
        }
        data._this.loadingHide()
    },  // 结算详情
    driverInfoList: async (store, data) => {
        data._this.loadingShow()
        let res = await service.userDriverSettlementList(data._this, data.form);
        console.log(res, "7777777");
        if (res.success && res.data) {
            store.commit('driverInfoList', res.data)
        }
        data._this.loadingHide()
    },    // 司机结算信息列表
    delPaySettle: async (store, data) => {
        let res = await service.deletePaySettlement(data._this, data.form)
        if (res.success && res.data) {
            return true
        }
    },  //删除结算信息 列表
    settleState: async (store, data) => {
        let res = await service.updatePaySettlementStatus(data._this, data.form);
        if (res.success && res.data) {
            return true
        }
    }, // 结算状态修改为已结算
    createSettle: async (store, data) => {
        data._this.loadingShow()
        let res = await service.createPaySettlement(data._this, data.form);
        if (res.success && res.data) {
            data._this.loadingHide()
            return true
        } // 保存
    },
    driverSettleList: async (store, data) => {
        data._this.loadingShow()
        let res = await service.userDriverSettlementPageList(data._this, data.form);
        if (res.success && res.data) {
            store.commit('driverSettleList', res.data)
        }
        data._this.loadingHide()
    },
    delDriverSettle: async (store, data) => {
        let res = await service.deleteUserDriverSettlement(data._this, data.form);
        if (res.success && res.data) {
            return true
        }
    },
    editDriverSettle: async (store, data) => {
        data._this.loadingShow()
        let res = await service.updateUserDriverSettlement(data._this, data.form);
        if (res.success && res.data) {
            data._this.loadingHide()
            return true
        }
    },
    delPaySettleDetail: async (store, data) => {
        data._this.loadingShow()
        let res = await service.deletePaySettlementDetail(data._this, data.form);
        if (res.success && res.data) {
            data._this.loadingHide()
            return true
        }
    },
    settleListStateNum: async (store, data) => {
        data._this.loadingShow()
        let res = await service.getSettlementStatusCount(data._this, data.form);
        if (res.success && res.data) {
            store.commit('settleListStateNum', res.data)
        }
        data._this.loadingHide()
    },
    exportDriverSettle: async (store, data) => {
        data._this.loadingShow()
        try {
            let res = await service.exportDriverSettle(data._this, data.form);
            console.log(res,"resres--9999resresresresres");
            if (res) {
                let d = new Date().getTime();
                let a = document.createElement('a');
                a.download = '司机结算信息导出表' + d + '.xls';
                a.href = window.URL.createObjectURL(res);
                a.click();

                data._this.loadingHide()
                return true
            }
        } catch (e) {
            data._this.loadingHide()
                this.messageError('下载失败')
        }
    },
    exportPaySettle: async (store, data) => {
        data._this.loadingShow()
        try {
            let res = await service.exportPaySettle(data._this, data.form);
            if (res) {
                let d = new Date().getTime();
                let a = document.createElement('a');
                a.download = '结算列表导出表' + d + '.xls';
                a.href = window.URL.createObjectURL(res);
                a.click();

                data._this.loadingHide()
                return true
            }
        } catch (e) {
            data._this.loadingHide()
            this.messageError('下载失败')
        }
    },
};
export default {
    state,
    mutations,
    actions
}