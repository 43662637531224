/**
* @Author:liaoyi
* @Date:2022/6/13
*/
<style lang="scss" scoped>
/deep/ {
    .el-input-group__append {
        .el-button--primary {
            background-color: #319bf7;
            color: #fff;
            border-radius: 0;
            border-color: #319bf7;
            min-width: 120px;

            &.is-disabled {
                background-color: #C0C4CC;
                color: #fff;
                border-radius: 0;
                border-color: #C0C4CC;
            }
        }
    }
}
</style>
<template>
    <el-input type="tel"
              placeholder="请输入验证码"
              :maxlength="6"
              clearable
              :value="value"
              v-bind="$attrs"
              @input="handleInput">
        <CountDownButton slot="append" v-bind="$attrs"/>
    </el-input>
</template>

<script type="text/ecmascript-6">
import CountDownButton from './button'

export default {
    name: 'CountDownInput',
    data () {
        return {
            code: ''
        }
    },
    components: {CountDownButton},
    props: {
        value: {
            type: [Number, String],
            default: null
        }
    },
    methods: {
        handleInput (val) {
            this.$emit('input', val)
        }
    }
}
</script>