import {combinationForm, findItem} from "@/common/js/common";
import {message} from 'element-ui';
import Loading from '@/common/js/loading';
import {
    rechargeProductInfoList
} from "@/www/urls/rechargeProductsUrls"
import {keepTwoDecimal} from '@/common/js/Utils';
import {
    getSeatModel,
    rechargeProductTypes
} from "@/data/index";

export const initForm = {
    operatorId: '',
    supplierId: '',
    carType: 0,
    // productForm: 0,
    rechargeProductNo: ''
};
export const modalData = {
    getSeatModel,
    // getSeatProductForm
}

const state = () => ({
    list: [],
    page: {
        pageIndex: 1,
        total: 0,
        pageSize: 10
    },
    dialogInfo: {
        isShow: false,
        dialogType: null,  // 0 有效期延长  2 启用  3封停  4日志
    },
    isShowDialog: true,
    initForm,
    modalData
});

const mutations = {
    // form请求
    change_recharge_product_form(state, data) {
        state.initForm = {
            ...state.initForm,
            ...data
        }
    },
    // 列表
    change_recharge_product_list(state, data) {
        state.list = data
    },
    // 切换页码
    change_recharge_product_page(state, data) {
        state.page = {
            ...state.page,
            ...data
        }
    },
    // 弹窗显示
    change_dialog_info(state, data) {
        state.dialogInfo = data
    },
};

const actions = {
    async search_recharge_product_list({commit, state}, {pageIndex, form}) {
        Loading.show();
        let initForm = form || state.initForm;
        let index = pageIndex || state.pageIndex;
        let data = {
            pageIndex: index,
            pageSize: state.page.pageSize,
            ...combinationForm(initForm),
            carType: initForm.carType ? initForm.carType : undefined
        };
        const res = await rechargeProductInfoList(data);
        if (res.success && res.data) {
            let list = res.data.data;
            list.forEach(item => {
                item.carTypeName = item.productType === 1 ? '全部' : (state.modalData.getSeatModel(item.carType) || {}).name;
                item.productTypeName = findItem(rechargeProductTypes, item.productType)
                item.yearlyPaymentPrice = keepTwoDecimal(item.yearlyPaymentPrice, 2);
                item.monthlyPaymentPrice = keepTwoDecimal(item.monthlyPaymentPrice, 2);
                item.quarterlyPaymentPrice = keepTwoDecimal(item.quarterlyPaymentPrice, 2);
                item.oncePrice = item.productType === 0 ? keepTwoDecimal(item.oncePrice, 2) : '无'
                item.oncePriceDay = item.productType === 0 ? keepTwoDecimal(item.oncePriceDay, 2) : '无'
            });
            commit('change_recharge_product_list', list);
            commit('change_recharge_product_page', {
                pageIndex: index,
                total: res.data.total
            });
            commit('change_recharge_product_form', initForm)
        } else message.error(res.errors[0].message);

        Loading.hide()
    },
    handle_dialog_info({commit}, form) {
        commit('change_dialog_info', form)
    },
};

export default {
    state,
    mutations,
    actions
}
