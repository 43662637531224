import config from '../../config'
export default {
    add: function (name, value, hours) { //修改或是添加cookie
        var expire = "";
        if (hours != null) {
            expire = new Date((new Date()).getTime() + hours * 3600000*12*30);
            expire = "; expires=" + expire.toGMTString();
        }
        document.cookie = config.storagePrefix+name + "=" + escape(value) + expire + ";path=/";
        //如果指定域名可以使用如下
        //document.cookie = name + "=" + escape(value) + expire + ";path=/;domain=findme.wang";
    },
    set(name, value, hours) {
        this.add(name, value, hours);
    },
    setObject(name, value, hours) {
        this.add(name, encodeURIComponent(JSON.stringify(value)), hours);
    },
    get: function (c_name) { //读取cookie
        c_name=config.storagePrefix+c_name;
        if (document.cookie.length > 0) {
            var c_start = document.cookie.indexOf(c_name + "=")
            if (c_start != -1) {
                c_start = c_start + c_name.length + 1
                var c_end = document.cookie.indexOf(";", c_start)
                if (c_end == -1) {
                    c_end = document.cookie.length
                }
                return unescape(document.cookie.substring(c_start, c_end))
            }
        }
        return "";
    },
    getObject(c_name) {
        const res = this.get(c_name);
        if (res)
            return JSON.parse(decodeURIComponent(res));
        else
            return {};
    },
    clearCookies: function () {
        let keys = document.cookie.match(/[^ =;]+(?==)/g);
        if (keys) {
            for (var i = keys.length; i--;)
                document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString() + ';path=/'
        }
    },
    removeItem(c_name) {
        let keys = document.cookie.match(/[^ =;]+(?==)/g);
        if (keys) {
            for (var i = keys.length; i--;)
                if (config.storagePrefix+c_name == keys[i])
                    document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString() + ';path=/'
        }
    }
};
