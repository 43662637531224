<style lang="scss">
    .phone-verification-code {
        .phone-number {
            .el-input-group__append {
                background: #ffffff;
            }
            .el-input__inner, .el-input-group__append {
                transition: border-color .2s;
            }
            .is-phone-eleven {
                color: #409eff;
            }

        }
        .phone-number:hover {
            .el-input__inner, .el-input-group__append {
                border-color: #c0c4cc;
            }
        }
        .phone-number.is-icon-focus {
            .el-input__inner, .el-input-group__append {
                border-color: #409eff;
                transition: border-color .2s;
            }
        }
        .el-form-item.is-success {
            .phone-number {
                .el-input__inner, .el-input-group__append {
                    border-color: #409eff;
                }
                .el-input__prefix {
                    color: #409eff;
                }

            }
            .phone-ver-code {
                .el-input__inner {
                    border-color: #409eff;
                }
                .el-input__prefix {
                    color: #409eff;
                }
            }
        }
        .el-form-item.is-error {
            .phone-number {
                .el-input__inner, .el-input-group__append {
                    border-color: #f56c6c;
                }
                .el-input__prefix {
                    color: #f56c6c;
                }
            }
            .phone-ver-code {
                .el-input__prefix {
                    color: #f56c6c;
                }
            }
        }

    }
</style>
<template>

    <div>
        <el-form ref="verificationCodeForm" :rules="rules" :model="form">
            <div class="phone-verification-code">

                <el-form-item :label-width="'0px'" prop="mobile">
                    <el-input
                            class="phone-number" :class="{'is-icon-focus':isPhoneFocus}"
                            placeholder="手机号码"
                            @focus="isPhoneFocus=true"
                            @blur="isPhoneFocus=false"
                            prefix-icon="icon icon-phone"
                            v-model="form.mobile"
                            auto-complete="off"
                            maxlength="11">
                        <el-button type="button" slot="append" @click="handleClick"
                                   :class="{'is-phone-eleven': isValidMobile&&!btnDisabled}">
                            {{buttonText ? buttonText + '重新获取' : '获取验证码'}}
                        </el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="" :label-width="'0px'" prop="code">
                    <el-input class="phone-ver-code"
                              :class="{'is-icon-focus':isVerCodeFocus}"
                              placeholder="验证码"
                              @focus="isVerCodeFocus=true"
                              @blur="isVerCodeFocus=false"
                              prefix-icon="icon icon-verification-code"
                              v-model="form.code"
                              auto-complete="off"
                              maxlength="6">
                    </el-input>
                </el-form-item>
            </div>
        </el-form>

    </div>

</template>

<script type="text/ecmascript-6">
    import {isCellphone} from '@/common/js/Utils'
    import {
        userIsRegister,
        forgetPwdSendVerifyCode,
        supplierOrOperatorIsRegister,
        tdsForgetPwdSendVerifyCode
    } from '../../service'

    export default {
        //定义模版数据
        data() {
            return {
                form: {
                    mobile: null,
                    code: null
                },
                buttonText: null,
                timer: null,
                isPhoneFocus: false,
                isVerCodeFocus: false,
                isValidMobile: false,
                btnDisabled: false,
                rules: {
                    mobile: [
                        {
                            validator: (rule, value, callback) => {
                                if (!value)
                                    callback(new Error('请输入手机号码'));
                                else if (!isCellphone(value))
                                    callback(new Error('手机号格式错误'));
                                else
                                    callback();
                            }, trigger: 'blur'
                        }
                    ],
                    code: [
                        {required: true, message: '请填写验证码', trigger: 'blur'},
                        {min: 6, max: 6, message: '请填写6位数的验证码', trigger: 'blur'}
                    ]
                },
                loginUserReDto: this.sessionStorage.getObject("loginuserReDto")
            }
        },
        props: [
            "defaultMobile",
            "defaultCode",
            "businessType",//0 注册 默认 1 忘记密码
            "extra"
        ],
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
            this.form.mobile = this.defaultMobile
            this.form.code = this.defaultCode
            let countDown = this.sessionStorage.getObject('register_count_down') || {}
            if (countDown && countDown.count && countDown.type === this.businessType) {
                this.form.mobile = countDown.mobile
                this.countDown(parseInt(countDown.count))
            }
            else
                this.sessionStorage.removeItem('register_count_down')
        },
        //定义事件方法
        methods: {
            async handleClick() {
                if (this.isValidMobile && !this.btnDisabled) {
                    this.btnDisabled = true;
                    this.countDown(Math.floor(+new Date() / 1000) + 60);
                    //忘记密码发送验证码处理
                    if (this.businessType === 1)
                        return this.forgetPwd()
                    //注册发送验证码处理
                    this.register()
                }
            },
            async register() {
                let ret = null;
                this.loginUserReDto =null;
                switch (this.projectName) {
                    case 'bms':
                        ret = await supplierOrOperatorIsRegister(this, this.form.mobile, this.extra.refType)
                        break;
                    case 'tds':
                        ret = await userIsRegister(this, this.form.mobile);
                        break;
                    default:
                }
                if (ret.success) {
                    if (ret.data.isApproval == 1) {
                        this.btnDisabled = false;
                        this.clearCountDown();
                        return this.$message.error("该手机号已提交过注册申请，请耐心等待")
                    }
                    if (ret.data.isRegisterManage == 1) {
                        this.btnDisabled = false;
                        this.clearCountDown();
                        return this.$message.error("手机号已注册，请直接登录")
                    }
                    if (ret.data.isRegisterUser == 1) {
                        this.loginUserReDto = ret.data.loginuserReDto
                    }
                    this.$message.success('验证码已发送至您的手机')
                }
                else {
                    this.$message.error(ret.errors[0].message)
                    this.btnDisabled = false;
                    this.clearCountDown();
                }
                this.sessionStorage.setObject("loginuserReDto", this.loginUserReDto);

            },
            async forgetPwd() {
                let ret = null;
                const form = {mobile: this.form.mobile}
                switch (this.projectName) {
                    case 'bms':
                        ret = await forgetPwdSendVerifyCode(this, form)
                        break;
                    case 'tds':
                        ret = await tdsForgetPwdSendVerifyCode(this, form)
                        break;
                    default:
                }
                if (ret.success) {
                    this.$message.success('验证码已发送至您的手机')
                } else {
                    this.btnDisabled = false;
                    this.clearCountDown();
                    this.$message.error(ret.errors[0].message)
                }
            },
            getFormData(callBack) {
                this.$refs["verificationCodeForm"].validate((valid) => {
                    if (valid)
                        callBack(this.form, this.loginUserReDto)
                    else
                        callBack()
                })
            },
            countDown(count) {
                this.btnDisabled = true;
                const data = count - Math.floor(+new Date() / 1000);
                if (data <= 0) {
                    this.sessionStorage.removeItem('register_count_down');
                    this.btnDisabled = false;
                    return false;
                }
                this.buttonText = data;
                this.sessionStorage.setObject('register_count_down', {
                    count: count,
                    type: this.businessType,
                    mobile: this.form.mobile
                });
                this.timer = setInterval(() => {
                    this.buttonText = count - Math.floor(+new Date() / 1000);
                    this.sessionStorage.setObject('register_count_down', {
                        count: count,
                        type: this.businessType,
                        mobile: this.form.mobile
                    });
                    if (this.buttonText === 0)
                       this.clearCountDown();
                }, 1000);
            },
            clearCountDown(){
                this.btnDisabled = false;
                this.sessionStorage.removeItem('register_count_down');
                clearInterval(this.timer)
                this.buttonText = null
            },
        },
        beforeDestroy() {
            clearInterval(this.timer)
        },
        //监听模版变量
        watch: {
            'form.mobile': {
                handler(n) {
                    if (isCellphone(n))
                        this.isValidMobile = true;
                    else
                        this.isValidMobile = false
                    this.$emit("updateForm", this.form)
                },
                immediate: true,
                deep: true
            },
            'form.code': {
                handler() {
                    this.$emit("updateForm", this.form)
                },
                immediate: true,
                deep: true
            }
        }

    }
</script>
