// 审核状态
export const auditStatusList = [
    {
        value: 0,
        label: '待审核',
        style: 'color: #E6A23C',
    },
    {
        value: 1,
        label: '审核通过',
        style: 'color: #67C23A',
    },
    {
        value: 2,
        label: '审核未通过',
        style: 'color: #F56C6C',
    },
    {
        value: 3,
        label: '已取消',
        style: 'color: #909399',
    }
];

// 获取审核状态名称
export const getAuditStatusLabel = (val) => auditStatusList.find(item => parseInt(val) === item.value);



//  支付类型
export const payTypeList = [
    {
        value: 0,
        label: '对公打款-银行',
    },
    {
        value: 1,
        label: '对公打款-微信',
    },
    {
        value: 2,
        label: '对公打款-支付宝',
    },
];

// 获取支付类型名称
export const getPayTypeLabel = (val) => payTypeList.find(item => parseInt(val) === item.value);

