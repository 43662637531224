//用户登录管理后台
import {sortTree} from '@/common/js/treeHelper'

export const backendLogin = async (_this, form) => {
    const ret = await _this.http('/galaxyLogin/login', form, "POST", false);
    if (ret.success) {
        ret.data.authorityList = sortTree(ret.data.authorityList, 'refId', 'nodeList')
    }
    return ret
}
//用户管理后台登出
export const backendLogout = async (_this) => {
    return await _this.http('/galaxyLogin/logout', null, "POST", false);
}
//用户backend信息获取
export const backendGetUserInfo = async (_this) => {
    return await _this.http('/galaxyLogin/loginUser', null, "GET", false);
}

//用户tds信息获取
export const tdsGetUserInfo = async (_this) => {
    return await _this.http('/galaxyLogin/loginUser', null, "GET", false);
}
//用户登录tds
export const tdsLogin = async (_this, form) => {
    return await _this.http('/galaxyLogin/login', form, "POST", false);
}
//用户tds登出
export const tdsLogout = async (_this) => {
    return await _this.http('/galaxyLogin/logout', null, "POST", false);
}

//注册
//供应商或运营商管理员是否存在
export const supplierOrOperatorAdminIsExists = async (_this, mobile) => {
    return await _this.http(`/register/supplierOrOperatorAdminIsExists/${mobile}`, null, "GET", false);
}
//注册 验证码校验
export const checkVerifyCode = async (_this, form) => {
    return await _this.http(`/register/checkVerifyCode`, form, "POST", false);
}
//供应商/运营商注册校验手机号是否已经注册
export const supplierOrOperatorIsRegister = async (_this, mobile, refType) => {
    return await _this.http(`/register/supplierOrOperatorIsRegister/${mobile}/${refType}`, null, "GET", false);
}
//tds注册
export const addRegister = async (_this, form) => {
    return await _this.http('/register/addRegister', form, "POST", false);
}
//B端管理员是否存在
export const tdsUserIsRegister = async (_this, mobile) => {
    return await _this.http(`/register/tdsUserIsRegister/${mobile}`, null, "GET", false);
}
//B端用户注册校验手机号是否已经注册
export const userIsRegister = async (_this, mobile) => {
    return await _this.http(`/register/userIsRegister/${mobile}`, null, "GET", false);
}

//忘记密码
//bms 忘记用户密码验证短信并进行修改
export const postForgetPwdNewPed = async (_this, form) => {
    return await _this.http(`/galaxyLoginuserApi/doGalaxyLoginuserApiForgetPwd`, form, "POST", false);
}
//bms 忘记用户密码发送验证码
export const forgetPwdSendVerifyCode = async (_this, form) => {
    return await _this.http(`/galaxyLoginuserApi/doGalaxyLoginuserApiForgetPwdSendVerifyCode`, form, "POST", false);
}
//BMS忘记用户密码验证短信验证码
export const bmsForgetPwdCheckVerifyCode = async (_this, form) => {
    return await _this.http(`/galaxyLoginuserApi/doGalaxyLoginuerApiCheckVerifyCode`, form, "POST", false);
}
//TDS忘记用户密码验证短信验证码
export const tdsForgetPwdCheckVerifyCode = async (_this, form) => {
    return await _this.http(`/galaxyLoginuserTds/doGalaxyLoginuerTdsCheckVerifyCode`, form, "POST", false);
}
//TDS忘记用户密码验证短信并进行修改
export const tdsForgetPwdChangePwd = async (_this, form) => {
    return await _this.http(`/galaxyLoginuserTds/doGalaxyLoginuserTdsForgetPwd`, form, "POST", false);
}
//TDS忘记用户密码发送验证码
export const tdsForgetPwdSendVerifyCode = async (_this, form) => {
    return await _this.http(`//galaxyLoginuserTds/doGalaxyLoginuserTdsForgetPwdSendVerifyCode`, form, "POST", false);
}



