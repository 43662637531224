
const couponList = () => import('../pages/promotionManage/coupon/couponList/');
const addCoupon = () => import('../pages/promotionManage/coupon/addCoupon/');
const editCoupon = () => import('../pages/promotionManage/coupon/editCoupon/');
const lookCoupon = () => import('../pages/promotionManage/coupon/lookCoupon/');
const activityList = () => import('../pages/promotionManage/activity/activityList/');
const addActivity = () => import('../pages/promotionManage/activity/addActivity/');
const editActivity = () => import('../pages/promotionManage/activity/editActivity/');
const lookActivity = () => import('../pages/promotionManage/activity/lookActivity/');

export default [
    {
        path: '/couponList', // 优惠券列表
        name: 'couponList',
        meta: {
            title: '优惠券列表',
            // auth: true
        },
        component: couponList
    },
    {
        path: '/addCoupon', // 添加优惠券
        name: 'addCoupon',
        meta: {
            title: '添加优惠券',
            // auth: true
        },
        component: addCoupon
    },
    {
        path: '/editCoupon/:id', // 编辑优惠券
        name: 'editCoupon',
        meta: {
            title: '编辑优惠券',
            // auth: true
        },
        component: editCoupon
    },
    {
        path: '/lookCoupon/:id', // 查看优惠券
        name: 'lookCoupon',
        meta: {
            title: '查看优惠券',
            // auth: true
        },
        component: lookCoupon
    },
    {
        path: '/activityList', // 活动列表
        name: 'activityList',
        meta: {
            title: '活动列表',
            // auth: true
        },
        component: activityList
    },
    {
        path: '/addActivity', // 添加活动
        name: 'addActivity',
        meta: {
            title: '添加活动',
            // auth: true
        },
        component: addActivity
    },
    {
        path: '/editActivity/:id', // 编辑活动
        name: 'editActivity',
        meta: {
            title: '编辑活动',
            // auth: true
        },
        component: editActivity
    },
    {
        path: '/lookActivity/:id', // 查看活动
        name: 'lookActivity',
        meta: {
            title: '查看活动',
            // auth: true
        },
        component: lookActivity
    }
]
