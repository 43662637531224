import config from '../../config'
import store from '@/www/vuex/index'

const url = config.api_url + '/galaxyConfig/config/';

export default async (name) => {
    try {
        let config = {
            method: "GET",
            headers: {}
        }
        //设置请求头
        const token = store.state.eTripToken
        const info = store.state.loginUserInfo;
        const user = info.loginuserDto;
        const port = info.loginPort;
        token && (config.headers["Header-Token"] = token);
        port && (config.headers["Header-LoginPort"] = port);
        user && user.id && (config.headers["Header-UserId"] = user.id);
        user && user.loginName && (config.headers["Header-UserName"] = encodeURI(user.loginName));
        const response = await fetch(`${url}${name}`, config);
        if (response.status === 200) {
            const responseJson = await response.json();
            if (responseJson.data)
                return JSON.parse(responseJson.data);
            else
                return false
        }
    } catch (e) {
        return false;
    }
}
