/**
* @Author:liaoyi
* @Date:2022/4/11
*/
<style lang="scss" scoped>
</style>
<template>
    <el-checkbox-group v-bind="$attrs" :loading="loading" :value="value" @change="handleChange">
        <slot></slot>
        <el-checkbox v-for="vm in getOptions"
                     :label="vm[replaceField.value]"
                     :key="vm[replaceField.key]">
            {{ vm[replaceField.label] }}
        </el-checkbox>
    </el-checkbox-group>
</template>

<script type="text/ecmascript-6">
export default {
    name: 'ApiCheckbox',
    //定义模版数据
    data () {
        return {
            optionsData: [],
            loading: false
        }
    },
    props: {
        api: {
            type: Function,
            default: null
        },
        params: {
            type: [Object, String],
            default: null
        },
        immediate: {
            type: Boolean,
            default: true
        },
        replaceField: {
            type: Object,
            default: () => ({
                label: 'label',
                value: 'value',
                key: 'value'
            })
        },
        value: {
            type: [String, Number, Object, Array],
            default: () => []
        },
        options: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        getOptions () {
            return this.api ? this.optionsData : this.options
        }
    },
    //主件被加载完成
    mounted () {
        if (this.immediate)
            this.loadData()
    },
    //定义事件方法
    methods: {
        async loadData () {
            if (!this.api)
                return;
            this.loading = true
            const ret = await this.api(this.params)
            if (ret.success && ret.data)
                this.optionsData = ret.data
            this.loading = false
        },
        handleChange (val) {
            this.$emit('input', val);
            this.$emit('change', val, this.getOptions.find((item) => item[this.replaceField.value] === val));
        },
        showLoading (bool) {
            this.loading = bool
        }
    },
    //监听模版变量
    watch: {
        params () {
            this.loadData();
        }
    }

}
</script>
