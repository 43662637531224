import http from './http'
import httpTrack from './httpTrack'
import conf from '@/config/'
import {Message} from 'element-ui';
import SectionContent from '@/components/sectionContent/'
import loading from './loading';
import sessionStorage from './sessionStorage'
import localStorage from './localStorage'
import cookieStorage from './cookieStorage'

export default {
    init: function (Vue, store) {

        window.bus = new Vue();

        let vue = Vue.prototype;

        //使用全局组件 记住页面滚动位置
        Vue.component(SectionContent.name, SectionContent);

        //测试线上区分
        vue.Debug = conf.Debug;

        //设置版本号
        vue.versionNumber = conf.versionNumber

        //项目区分
        vue.projectName = process.env_project.MODULE_ENV;

        //浏览器的宽高
        vue.screen = {
            w: window.screen.width,
            h: window.screen.height
        }
        /*
         挂载http请求
         */
        vue.http = http;
        vue.httpTrack = httpTrack;
        /*
        默认图片
         */
        vue.imgs = {
            banner: require("../../www/assets/images/global_default.jpg")
        }
        /*
        400电话
         */
        vue.phoneNumber400 = '400-821-0189';

        /*
       本地存储
        */
        vue.sessionStorage = sessionStorage;
        vue.localStorage = localStorage;
        vue.cookieStorage = cookieStorage;
        /*
       获取用户信息
        */
        vue.getUserInfo = () => {
            if (store.state.eTripToken)
                return store.state.loginUserInfo
        };
        /*
        错误
         */
        let messageErrorInfo = function (errorText) {
            if (errorText) {
                Message.error({
                    message: errorText,
                    center: true
                });
            } else {
                Message.error({
                    message: "Sorry, 请求失败",
                    center: true
                });
            }
        };

        vue.messageError = messageErrorInfo;

        vue.loadingShow = loading.show;

        vue.loadingHide = loading.hide;
        vue.filterDate = Vue.filter("dateCus");
        vue.filterCurrency = Vue.filter("currency");
        vue.filterFormatDesc = Vue.filter("formatDesc");
    }
}
