/**
* Created by hansxing on 2018/8/21.
*/
<style lang="scss" scoped>
    .section-forget-pwd {
        .forget-pwd-header {
            line-height: 30px;
            .header-return {
                cursor: pointer;
                margin-bottom: 10px;
                i {
                    font-size: 28px;
                }
            }
            .header-title {
                /*float: left;*/
                font-family: PingFangSC-Regular;
                font-size: 24px;
                color: #666666;
            }

        }
        .forget-pwd-cont {
            padding-top: 30px;

            .section-footer {
                margin-top: 40px;
                .el-button {
                    font-size: 16px;
                    width: 100%;
                    padding: 17px 20px;
                    border: 0;
                    background-image: linear-gradient(90deg, #319BF7 0%, #31D1F7 100%);
                    margin-top: 15px;
                    &.backstage-footer-supplier {
                        background-image: linear-gradient(90deg, #25D262 0%, #66E3B7 100%);
                    }
                }
                .footer-confirm-reg {
                    font-family: PingFangSC-Regular;
                    font-size: 20px;
                    color: #666666;
                }
                .el-button + .el-button {
                    margin-left: 0;
                }

            }
        }

    }
</style>
<style lang="scss">
    .forget-pwd-cont {
        .el-input__prefix, .el-input__icon, .el-input__suffix {
            transition: all .1s;
            -webkit-transition: all .1s;
        }
        .is-icon-focus {
            .el-input__prefix {
                color: #409EFF;
            }
            .el-input__suffix {
                color: #409EFF;
            }
        }

        .step-forget-pwd {
            &.is-error {
                .el-input__prefix {
                    color: #f56c6c;
                }
            }
            &.is-success {
                .el-input__inner {
                    border-color: #319BF7;
                }
                .el-input__prefix {
                    color: #319BF7;
                }
            }

        }

    }

</style>
<template>

    <div v-loading="loading">
        <div class="section-forget-pwd">
            <div class="forget-pwd-header">
                <div class="header-return" @click="goBack">
                    <i class="icon icon-left-return"></i>
                </div>
                <ul class="clear">
                    <li class="header-title">
                        <span v-if="tabIndex===0">忘记密码？</span>
                        <span v-if="tabIndex===1">设置新密码</span>
                    </li>
                </ul>
            </div>
            <div class="forget-pwd-cont">
                <el-form :model="form" ref="forgetPwdForm" :rules="rules">
                    <verification-code :defaultMobile="form.mobile"
                                       :defaultCode="form.verifyCode"
                                       ref="verificationCode"
                                       v-if="tabIndex===0"
                                       :businessType="1">
                    </verification-code>

                    <el-form-item v-if="tabIndex===1"
                                  prop="newPwd"
                                  label=""
                                  :label-width="'0px'"
                                  class="step-forget-pwd">
                        <el-input
                                type="text"
                                :class="{'is-icon-focus':pwd.focus}"
                                placeholder="密码由6位以上数字、字母组合"
                                @focus="pwd.focus=true"
                                @blur="pwd.focus=false"
                                prefix-icon="icon icon-password"
                                v-model="form.newPwd"
                                auto-complete="off">
                        </el-input>
                    </el-form-item>
                    <!--管理后台登录-->
                    <div class="section-footer">
                        <el-button type="primary" @click="handleClick">{{tabIndex === 0 ? '下一步' : '确定'}}</el-button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>

</template>

<script type="text/ecmascript-6">
    import verificationCode from '../verificationCode/index.vue'
    import {
        postForgetPwdNewPed,
        tdsForgetPwdChangePwd,
        bmsForgetPwdCheckVerifyCode,
        tdsForgetPwdCheckVerifyCode
    } from '../../service'

    export default {
        //定义模版数据
        data() {
            const account = this.localStorage.getObject('account') || {};
            return {
                tabIndex: 0,
                pwd: {
                    focus: false
                },
                form: {
                    mobile: account.loginName,
                    newPwd: null,
                    verifyCode: null
                },
                rules: {
                    newPwd: [
                        {required: true, message: '请填写密码', trigger: 'blur'},
                        {min: 6, max: 20, message: '密码由6位以上数字、字母组合', trigger: 'blur'}
                    ],
                },
                loading: false
            }
        },
        components: {
            verificationCode
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
        },
        //定义事件方法
        methods: {
            async handleClick() {
                if (this.tabIndex === 0) {
                    this.loading = true;
                    this.$refs["verificationCode"].getFormData(async (form) => {
                        if (form) {
                            this.form.mobile = form.mobile;
                            this.form.verifyCode = form.code

                            let ret = {}, _form = {
                                mobile: form.mobile,
                                verifyCode: form.code
                            }
                            switch (this.projectName) {
                                case 'bms':
                                    ret = await bmsForgetPwdCheckVerifyCode(this, _form);
                                    break;
                                case 'tds':
                                    ret =await tdsForgetPwdCheckVerifyCode(this,_form);
                                    break
                            }
                            if(ret.success) {
                                if(ret.data.checkVerifyCode)
                                    this.tabIndex += 1;
                                else
                                    this.$message.error("验证码错误")
                            }
                            else
                                this.$message.error(ret.errors[0].message)
                            this.loading = false
                        }
                        else
                            this.loading = false

                    })
                }
                else {
                    this.loading = true;
                    this.$refs["forgetPwdForm"].validate(async (valid) => {
                        if (valid) {
                            let ret = {};
                            switch (this.projectName) {
                                case 'bms':
                                    ret = await  postForgetPwdNewPed(this, this.form);
                                    break;
                                case 'tds':
                                    ret = await  tdsForgetPwdChangePwd(this, this.form);
                                    break;
                            }
                            if (ret.success) {
                                this.$message.success("密码修改成功！")
                                this.goToLogin()
                            } else {
                                this.$message.error(ret.errors[0].message)
                            }
                            this.loading = false
                        }
                        else
                            this.loading = false
                    })
                }
            },
            goBack() {
                if (this.tabIndex === 0) {
                    this.goToLogin()
                } else
                    this.tabIndex = 0;
            },
            goToLogin() {
                this.localStorage.setObject('account', {
                    loginName: this.form.mobile
                })
                this.$store.dispatch("CHANGE_LOGIN_OR_REGISTER", {
                    type: 0,
                    show: true
                });
            }
        },
        //监听模版变量
        watch: {}

    }
</script>

