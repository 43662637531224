import Vue from 'vue'
import * as Sentry from '@sentry/browser';
import {Vue as VueIntegration} from '@sentry/integrations';

export default {
    install () {
        if (process.env_project.BULID_ENV !== 'debug')
            Sentry.init({
                dsn: 'https://6b548f9bb7af45d0b8bcd7650856033b@service-monitor.900u.xyz/4',
                integrations: [new VueIntegration({Vue, attachProps: true})],
                environment: `${process.env_project.BULID_ENV}`,
                release: `${process.env_project.BULID_ENV}-${process.env_project.VERSION}`
            })
    },
    captureException (error) {
        Sentry.captureException(error)
    },
    setUser (user = {}) {
        Sentry.configureScope(function (scope) {
            scope.setUser({...user});
        });
    }
}
