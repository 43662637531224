/**
* @Author:liaoyi
* @Date:2022/4/11
*/
<style lang="scss" scoped>
</style>
<template>
    <el-cascader ref="cascader"
                 class="full-width"
                 v-bind="$attrs"
                 :options="options"
                 :loading="loading"
                 :value="value"
                 @change="handleChange"/>
</template>

<script type="text/ecmascript-6">
export default {
    name: 'ApiCascader',
    //定义模版数据
    data () {
        return {
            options: [],
            loading: false
        }
    },
    props: {
        api: {
            type: Function,
            default: null
        },
        params: {
            type: Object,
            default: () => ({})
        },
        immediate: {
            type: Boolean,
            default: true
        },
        value: {
            type: Array,
            default: () => []
        }
    },
    //主件被加载完成
    mounted () {
        if (this.immediate)
            this.loadData()
    },
    //定义事件方法
    methods: {
        async loadData () {
            this.loading = true;
            const ret = await this.api(this.params)
            if (ret.success && ret.data)
                this.options = ret.data
            this.loading = false
        },
        getCheckedNodes () {
            return this.$refs['cascader'].getCheckedNodes()
        },
        handleChange (val) {
            this.$emit('input', val);
            this.$emit('change', val, this.$refs['cascader'].getCheckedNodes());
        }
    },
    //监听模版变量
    watch: {
        params () {
            this.loadData();
        }
    }

}
</script>
