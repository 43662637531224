/*
根据陈寅搭建后台系统 统一的http请求
 */
import axios from 'axios'
import conf from '../../config/'
import {clearUserLoginInfo} from "./user";
import store from '@/www/vuex/'
import sessionStorage from '@/common/js/sessionStorage'
import {message} from 'element-ui'

axios.defaults.timeout = 3 * 60 * 1000;

const errorMessage = "抱歉，服务繁忙，请重试！";

const axiosConfig = (url, form = {}, method = 'GET', responseType = "json") => {
    if (url.indexOf('http') == -1) url = conf.api_url + url;
    let config = {
        method: method,
        url: url,
        headers: {
            "Content-Type": "application/json"
        },
        data: form,
        withCredentials: false,
        responseType: responseType,
    }
    //设置请求头
    const token = store.state.eTripToken
    const info = store.state.loginUserInfo;
    const user = info.loginuserDto;
    const port = info.loginPort;
    token && (config.headers["Header-Token"] = token);
    port && (config.headers["Header-LoginPort"] = port);
    user && user.id && (config.headers["Header-UserId"] = user.id);
    user && user.loginName && (config.headers["Header-UserName"] = encodeURI(user.loginName));
    return config;
}

const notLogin = () => {
    clearUserLoginInfo();
    sessionStorage.set("nextPath", window.location.hash.replace('#', ''))
    store.dispatch('CHANGE_LOGIN_OR_REGISTER', {
        show: true,
        type: 0
    });
    window.location.href = '#/home'
}

export default async function (url, form = {}, method = 'GET', showError = true, responseType = "json") {
    if (method === 'GET') {
        if (form) {
            url += '?';
            for (let key in form) {
                url = url + key + "=" + form[key] + '&';
            }
        }
    }
    const config = axiosConfig(url, form, method, responseType);
    try {
        const result = await axios(config);
        if (result.status === 200) {
            const data = result.data;
            if (data && !data.success && data.errors && data.errors.length > 0) {
                if (showError)
                    message.error(data.errors[0].message);
                //未登录
                if (data.errors[0].code === "60022") {
                    notLogin()
                }
            }
            return data;
        } else {
            message.error(`请求失败${result.status}`);
            return {
                success: false,
                errors: [{
                    code: result.status,
                    message: `请求失败${result.status}`
                }]
            }
        }
    } catch (err) {
        console.log(err);
        message.error(errorMessage);
        return {
            success: false,
            errors: [{
                message: errorMessage
            }]
        }
    }
}
