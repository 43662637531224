import http from '@/common/js/http'

// 新增客服时间
export const doPostBackendListInsuranceOrder = async (form) => {
    return await http('/galaxyOrder/doPostBackendListInsuranceOrder', form, "POST");
};
//出行人姓名
export const selectTripUserInfoByName = async (query) => {
    return await http(`/galaxyOrder/selectTripUserInfoByName/${query}`);
};
// 司导姓名
export const doGalaxySelectDriverInfoByName = async (form) => {
    return await http('/galaxyDriverApproveApi/doGalaxySelectDriverInfoByName', form, "POST");
};
//车牌号
export const doGalaxyGetCarInfoByNumberPlate = async (query) => {
    return await http(`/galaxyDriverApproveApi/doGalaxyGetCarInfoByNumberPlate/${query}`);
};
// 产品名称
export const selectProductByName = async (form) => {
    return await http('/galaxyProduct/selectProductByName', form, "POST");
};

// BMS导出投保订单列表
export const doBmsExportInsuranceOrder = async (form) => {
    return await http('/galaxyOrder/doBmsExportInsuranceOrder', form, 'POST', true, 'blob');
};
