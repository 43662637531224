
export default {
    CHANGE_LOGIN_OR_REGISTER: 'CHANGE_LOGIN_OR_REGISTER',
    CHANGE_USER_INFO: 'CHANGE_USER_INFO',
    CHANGE_USER_LOGIN_INFO: 'CHANGE_USER_LOGIN_INFO',
    CHANGE_LOCATION: 'CHANGE_LOCATION',
    CHANGE_VIEW_TITLE: 'CHANGE_VIEW_TITLE',
    CHANGE_CHANNEL: 'CHANGE_CHANNEL',
    CHANGE_SEAT_MODEL_LIST: 'CHANGE_SEAT_MODEL_LIST',
}
