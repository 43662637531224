const AddQuote = () => import(/* webpackChunkName: "group-quote" */ '../pages/quotationTool/addQuote/index');
const EditQuote = () => import(/* webpackChunkName: "group-quote" */ '../pages/quotationTool/editQuote/index');
const QuoteList = () => import(/* webpackChunkName: "group-quote" */ '../pages/quotationTool/quoteList/index');
const QuoteTool = () => import(/* webpackChunkName: "group-quote" */ '../pages/quotationTool/quoteTool/index');
const QuoteDate = () => import(/* webpackChunkName: "group-quote" */ '../pages/quotationTool/quoteDate/index');
const SingleBasicSetting = () => import(/* webpackChunkName: "group-quote" */ '../pages/quotationTool/singleBasicSetting/index');
const ServiceTime = () => import(/* webpackChunkName: "group-quote" */ '../pages/quotationTool/serviceTime/index');
const OrderContact = () => import(/* webpackChunkName: "group-quote" */ '../pages/quotationTool/orderContact/index');
const AnswerContact = () => import(/* webpackChunkName: "group-quote" */ '../pages/quotationTool/answerContact/index');
const PeriodInventory = () => import(/* webpackChunkName: "group-quote" */ '../pages/quotationTool/periodInventory/index');
const QuoteDetail = () => import(/* webpackChunkName: "group-quote" */ '../pages/quotationTool/quoteDetail/index');
const FenceList = () => import(/* webpackChunkName: "group-quote" */ '../pages/quotationTool/fenceManage/fenceList/index');
const FenceMap = () => import(/* webpackChunkName: "group-quote" */ '../pages/quotationTool/fenceManage/fenceMap/index');
const CtripPriceShielding = () => import(/* webpackChunkName: "group-quote" */ '../pages/quotationTool/ctripPriceShielding/index');
const vehicleInventoryMonitor = () => import(/* webpackChunkName: "group-quote" */ '../pages/quotationTool/vehicleInventoryMonitor/index');

export default [
    {
        path: '/addQuote', // 新增报价规则
        name: 'addQuote',
        meta: {
            title: '新增报价规则',
            auth: true
        },
        component: AddQuote,
    },
    {
        path: '/editQuote/:id',
        name: 'editQuote',
        meta: {
            title: '编辑报价规则'
        },
        component: EditQuote,
    },
    {
        path: '/quoteDetail/:id',
        name: 'quoteDetail',
        meta: {
            title: '报价规则详情'
        },
        component: QuoteDetail,
    },
    {
        path: '/quoteList', // 报价规则列表
        name: 'quoteList',
        meta: {
            title: '报价规则列表',
            auth: true
        },
        component: QuoteList
    },
    {
        path: '/quoteTool', // 报价工具
        name: 'quoteTool',
        meta: {
            title: '报价工具',
            auth: true
        },
        component: QuoteTool,
    },
    {
        path: '/quoteDate/:id', // 报价工具
        name: 'quoteDate',
        meta: {
            title: '特定日期设置'
        },
        component: QuoteDate
    },
    {
        path: '/singleBasicSetting', // 应单基础设置
        name: 'singleBasicSetting',
        meta: {
            title: '应单基础设置'
        },
        component: SingleBasicSetting,
    },
    {
        path: '/serviceTime', // 客服时间管理
        name: 'serviceTime',
        meta: {
            title: '客服时间管理',
            auth: true
        },
        component: ServiceTime,
    },
    {
        path: '/orderContact', // 订单联系人管理
        name: 'orderContact',
        meta: {
            title: '订单联系人管理',
            auth: true
        },
        component: OrderContact,
    },
    {
        path: '/answerContact', // 应单联系人管理
        name: 'answerContact',
        meta: {
            title: '应单联系人管理',
            auth: true
        },
        component: AnswerContact
    },
    {
        path: '/periodInventory/:id',
        name: 'periodInventory',
        meta: {
            title: '库存时段设置',
            auth: false
        },
        component: PeriodInventory
    },
    {
        path: '/fenceList',
        name: 'fenceList',
        meta: {
            title: '围栏管理',
            auth: true
        },
        component: FenceList
    },
    {
        path: '/fenceMap',
        name: 'fenceMap',
        meta: {
            title: '新增围栏',
            auth: false
        },
        component: FenceMap
    },
    {
        path: '/ctripPriceShielding',
        name: 'ctripPriceShielding',
        meta: {
            title: '携程价格屏蔽',
            auth: true
        },
        component: CtripPriceShielding
    },
    {
        path: '/vehicleInventoryMonitor',
        name: 'vehicleInventoryMonitor',
        meta: {
            title: '库存监管',
            auth: true
        },
        component: vehicleInventoryMonitor
    }
]
