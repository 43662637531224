let Debug = true;//测试 开发模式自由匹配

const MODULE = process.env_project.MODULE_ENV;
window.version = process.env_project.VERSION;
const protocol = document.location.protocol;

if (process.env_project.BULID_ENV === 'fat')
    Debug = true;
if (process.env_project.BULID_ENV === 'pro')
    Debug = false;

const port = {
    'bms': 'bms',
    'bms_h5': 'bms',
}
let api_url = `${protocol}//i${port[MODULE]}.900u.xyz`,
    B2B_url = `${protocol}//900etrip.com`,
    host_cdn = `${protocol}//cdn.900etrip.com/static/${MODULE}/3/`,
    http_url = 'http://server2.900etrip.com/',
    storagePrefix = `${MODULE}_3_`,
    serviceName = {
        "bms": "GALAXY-API-SERVICE",
        "tds": "GALAXY-TDS-SERVICE"
    },
    host_urls = [],
    amap_url = '//webapi.amap.com/maps?v=1.4.15&key=0bb2ba3ff63722595ab21b36357841cc',
    amap_track_sid = "29677";

if (Debug) {
    api_url = `${protocol}//i${port[MODULE]}-fat.900u.xyz`;
    B2B_url = `${protocol}//emu.900u.xyz`;
    http_url = 'http://emu.900etrip.com/';
    storagePrefix += 'dev_';
    host_cdn = "/";
    host_urls = [];
    amap_track_sid = 24378;
    if (process.env_project.BULID_ENV === 'debug') {
        //本地测试  bms: 4000   tds: 4001
        // api_url = "http://192.168.1.32:4000"//bms
        // api_url="http://192.168.1.3:4001"//tds
    }
}

if (process.env_project.SYSTEM_NAME === 'zsl') {
    api_url = `https://zsl.900u.xyz/bms`
}


host_cdn += "static/";

export default {
    api_url,
    B2B_url,
    http_url,
    storagePrefix,
    host_urls,
    Debug,
    serviceName,
    host_cdn,
    protocol,
    amap_url,
    amap_web_server: 'a265caec4218ac9b9f7e82d0997c7eaf',
    amap_track_sid,
    bmap_web_server: 'GTg0zZ8IZkmg9n609f4M5ofX00pgQ9cH'
}
