const driverLearning = () => import('../pages/driverManage/driverLearning/list')
const questionList = () => import('../pages/driverManage/driverLearning/question/index.vue')
const coursewareList = () => import('../pages/driverManage/driverLearning/courseware/index.vue')
const completeDetails = () => import('../pages/driverManage/driverLearning/list/completeDetails.vue')
const safeAgreement = () => import('../pages/driverManage/safeAgreement/index.vue')
const SafeAgreementPrint = () => import('../pages/driverManage/safeAgreement/print.vue')
const driverLearningAdd = () => import('../pages/driverManage/driverLearning/add/add.vue')
const driverLearningDetail = () => import('../pages/driverManage/driverLearning/add/detail.vue')
const driverLearningEdit = () => import('../pages/driverManage/driverLearning/add/edit.vue')

export default [
    {
        path: '/driverLearning',
        name: 'driverLearning',
        meta: {
            title: '宣导内容列表'
        },
        component: driverLearning
    },
    {
        path: '/driverLearningAdd',
        name: 'driverLearningAdd',
        meta: {
            title: '发布宣导',
        },
        component: driverLearningAdd
    },
    {
        path: '/driverLearningDetail',
        name: 'driverLearningDetail',
        meta: {
            auth: false,
            title: '查看宣导',
        },
        component: driverLearningDetail
    },
    {
        path: '/driverLearningEdit',
        name: 'driverLearningEdit',
        meta: {
            auth: false,
            title: '编辑宣导',
        },
        component: driverLearningEdit
    },
    {
        path: '/questionList',
        name: 'questionList',
        meta: {
            title: '司机题库'
        },
        component: questionList
    },
    {
        path: '/coursewareList',
        name: 'coursewareList',
        meta: {
            title: '培训课件'
        },
        component: coursewareList
    },
    {
        path: '/completeDetails',
        name: 'completeDetails',
        meta: {
            title: '完成情况',
            auth: false
        },
        component: completeDetails
    },
    {
        path: '/safeAgreement',
        name: 'safeAgreement',
        meta: {
            title: '安全责任书',
            auth: true
        },
        component: safeAgreement
    },
    {
        path: '/safeAgreement/print',
        name: 'SafeAgreementPrint',
        meta: {
            title: '安全责任书',
            auth: false
        },
        component: SafeAgreementPrint
    }
]
