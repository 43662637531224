const SettleList = () => import(/* webpackChunkName: "group-settle" */ '../pages/thirdSettleMan/settleList/index');
const AddSettle = () => import(/* webpackChunkName: "group-settle" */ '../pages/thirdSettleMan/addSettle/index');
const EditSettle = () => import(/* webpackChunkName: "group-settle" */ '../pages/thirdSettleMan/editSettle/index');
const DriverSettleList = () => import(/* webpackChunkName: "group-settle" */ '../pages/thirdSettleMan/driverSettleList/index');

export default [
    {
        path: '/settleList', // 结算记录列表
        name: 'settleList',
        meta: {
            title: '结算记录列表',
            auth:true
        },
        component: SettleList
    },
    {
        path: '/addSettle',
        name: 'addSettle',
        meta: {
            title: '新增结算记录',
            auth:true
        },
        component: AddSettle
    },
    {
        path: '/editSettle/:id/:type',  // type 0 查看 1 结算 2编辑
        name: 'editSettle',
        meta: {
            title: '编辑结算'
        },
        component: EditSettle
    },
    {
        path: '/driverSettleList',
        name: 'driverSettleList',
        meta: {
            title: '司机结算信息列表',
            auth:true
        },
        component: DriverSettleList
    }
]
