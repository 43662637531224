import http from '@/common/js/http'

//司机车辆充值服务列表
export const rechargeDriverInfoList = async (form) => {
    return await http('/galaxyRechargeAuditInfoApi/rechargeDriverInfoList', form, "POST");
};

//司机车辆充值服务列表
export const rechargeDriverInfoUpdate = async (form) => {
    return await http('/galaxyRechargeAuditInfoApi/rechargeDriverInfoUpdate', form, "POST");
};


//启停用
export const rechargeDriverInfoEnable = async (form) => {
    return await http('/galaxyRechargeAuditInfoApi/rechargeDriverInfoEnable', form, "POST");
};

//充值审核信息日志
export const rechargeLogDtoList = async (form) => {
    return await http('/galaxyRechargeAuditInfoApi/rechargeLogDtoList', form, "POST");
};
