import cookieStorage from '../js/cookieStorage'
import localStorage from '../js/localStorage'
import {channel, seatModelList} from "../../data/index";
import Sentry from '@/common/js/sentry'

let userInfo = cookieStorage.getObject("userInfo"),
    eTripToken = cookieStorage.get("eTrip_token"),
    loginUserInfo = localStorage.getObject("loginUserInfo");
if (!userInfo || !eTripToken || !loginUserInfo) {
    userInfo = {}
    eTripToken = null
    loginUserInfo = {}
}
if (loginUserInfo && loginUserInfo.loginuserDto)
    Sentry.setUser({
        id: loginUserInfo.loginuserDto.mobile
    })

export default {
    direction: 'forward',
    viewTitle: {
        title: '司导端',
        showBack: true,//显示返回
        preventGoBack: false//阻止返回
    },
    userInfo,
    eTripToken,
    loginUserInfo,
    loginOrRegister: {
        show: false,
        type: 0,//0登录 1 注册
    },
    location: {
        cityName: "上海市"
    },
    channel,
    seatModelList
}
