<style lang="scss" scoped>
.eTrip-home-aside {
    background: #002140;
    height: 100%;
    height: 100vh;
    position: relative;
    transition: all 0.3s ease;
    z-index: 1999;

    .home-head-logo {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1999;
        width: 220px;
        height: 64px;
        background: #002140;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -o-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        transition: all 0.3s ease;

        .head-logo-center {
            cursor: pointer;
            line-height: 40px;
            /*margin: auto 30px;*/
            margin-left: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        img {
            width: 40px;
            vertical-align: middle;
            margin-top: -1px;
        }

        span {
            color: #ffffff;
            font-size: 16px;
            font-family: 'PingFangSC-Medium';
            display: inline-block;
            margin-left: 5px;
        }
    }
}

</style>
<style lang="scss">

.eTrip-home-aside {
    .el-menu {
        border-right: 0;
    }

    .home-menu {
        margin-top: 64px;

        .el-submenu__title {
            height: 44px;
            line-height: 44px;

            &:hover {
                /*background: #1890FF !important;*/
                /*background: #369af7 !important;*/
                background: rgba(255, 255, 255, 0.1) !important;
            }

            span {
                padding-left: 4px;
            }
        }

        .home-page-submenu {
            .el-menu-item {
                padding-left: 44px !important;
            }
        }

        .el-menu-item {
            height: 44px;
            line-height: 44px;

            &:hover {
                /*background: #369af7 !important;*/
                background: rgba(255, 255, 255, 0.1) !important;
            }
        }

        .el-submenu__title,
        .el-menu-item {
            color: rgba(255, 255, 255, .65) !important;
            font-size: 14px;

            i {
                color: rgba(255, 255, 255, .65);
            }

            &:hover {
                color: #fff !important;

                i {
                    color: #fff;
                }
            }
        }

        .el-menu-item-group {
            .el-menu-item-group__title {
                display: none;
            }
        }

        .is-opened {
            .el-submenu__title {
                background: #001529 !important;
                color: #fff !important;

                &:hover {
                    /*background: #369af7 !important;*/
                    background: rgba(255, 255, 255, 0.1) !important;
                }

                i {
                    color: #fff;
                }
            }

            .el-menu {
                .el-menu-item-group {
                    .el-menu-item {
                        background: #001529 !important;
                        padding-left: 44px !important;

                        &:hover {
                            /*background: #369af7 !important;*/
                            background: rgba(255, 255, 255, 0.1) !important;
                        }
                    }

                    .el-menu-item.is-active {
                        background: #1890FF !important;
                        color: #fff !important;
                        transition: all 0.3s ease;
                    }
                }
            }

            /*background: #001529 !important;*/

        }

    }

}

.eTrip-home-aside::-webkit-scrollbar,
.home-menu::-webkit-scrollbar {
    display: none;
}

.el-menu--collapse {
    width: 64px;
}

.el-aside {
    color: #333;
}

.menu-contractions-sidebar:not(.el-menu--collapse) {
    width: 220px;
    min-height: 120px;
    height: auto;
}

.eTrip-home-aside {
    &.menu-contractions-com {
        width: 64px !important;
        transition: all 0.3s ease;
    }

    .home-head-logo.menu-contractions-logo {
        width: 64px;
        transition: all 0.3s ease;

        .head-logo-center {
            margin: auto;
        }
    }

    .menu-contractions-i {
        .el-submenu__title {
            padding-left: 24px !important;
            /*text-align: center;*/
        }

    }

    .menu-contractions-home {
        &.home-page-select.is-active {
            &:hover {
                background: #1890FF !important;
            }

            color: #fff !important;

            i {
                color: #fff;
            }

        }

        &.el-menu-item {
            height: 44px;
            line-height: 44px;

            span {
                padding-left: 4px;
            }

        }

        transition: all 0.3s ease;

        .el-tooltip {
            padding-left: 24px !important;
        }

        &.is-active {
            background: #1890FF !important;
            transition: all 0.3s ease;

        }
    }
}

.el-menu--vertical {
    .el-menu--popup {
        padding: 0;
    }

    .el-menu--popup-right-start {
        margin: 0;
        margin-left: 5px;
    }

    .el-menu-item-group {
        .el-menu-item-group__title {
            display: none;
        }

        .el-menu-item.is-active {
            background: #1890FF !important;
            color: #fff;
        }

        .el-menu-item {
            height: 44px;
            line-height: 44px;

            &:hover {
                /*background: #369af7 !important;*/
                background: rgba(255, 255, 255, 0.1) !important;
            }
        }
    }

}
</style>
<template>
    <div>
        <el-aside width="220px" class="eTrip-home-aside"
                  :class="{ 'menu-contractions-com':isFoldState}">
            <div class="home-head-logo" :class="{'menu-contractions-logo':isFoldState}">
                <div class="head-logo-center" @click="goHome()">
                    <img :src="logo"/>
                    <span v-if="!isFoldState" :style="titleStyle">{{ title }}</span>
                </div>
            </div>
            <el-menu class="home-menu menu-contractions-sidebar"
                     :default-active="$route.path"
                     :unique-opened='true'
                     :collapse="isFoldState"
                     background-color="#002140"
                     text-color="#fff" @select="selectMenu">
                <el-menu-item class="menu-contractions-home home-page-select" :index="home.content">
                    <i class="icon" :class="home.icon"></i>
                    <span slot="title" v-text="home.title">首页</span>
                </el-menu-item>
                <el-submenu :index="vm.id" class="home-page-submenu" :class="{'menu-contractions-i':isFoldState}"
                            v-for="(vm,idx) in subMenus" :key="idx">
                    <template slot="title">
                        <i class="icon" :class="vm.content"></i>
                        <span slot="title" v-text="vm.name"></span>
                    </template>
                    <el-menu-item-group style="background: #001529" v-for="(vmp,idxp) in vm.nodeList" :key="idxp">
                        <el-menu-item :index="'/'+vmp.content" v-text="vmp.name">
                            机构列表
                        </el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
            </el-menu>

        </el-aside>
    </div>

</template>

<script type="text/ecmascript-6">
import {getQueryString} from "@/common/js/Utils"
import menus from "./menus.json"

function sortMenu(menus = []) {
    menus.sort((a, b) => {
        return a.refId - b.refId;
    });
    menus.forEach((data) => {
        if (data.nodeList)
            sortMenu(data.nodeList);
    });
    return menus;
}

export default {
    //定义模版数据
    data() {
        return {
            home: menus.home,
            sidebarListCom: menus.sidebarListCom,
            view: '首页'
        }
    },
    props: ["isFoldState"],
    //计算属性
    computed: {
        logo() {
            return process.env_project.SYSTEM_NAME === 'zsl' ? require('@/assets/images/logo-zsl.png') :
                require('@/assets/images/logo.png')
        },
        title() {
            return process.env_project.PROJECT_NAME
        },
        titleStyle() {
            return process.env_project.SYSTEM_NAME === 'zsl' ? {fontSize: '13px'} : null
        },
        subMenus() {
            if (getQueryString("menus") === 'all')
                return this.sidebarListCom
            else {
                let menus = this.$store.state.loginUserInfo.authorityList &&
                    this.$store.state.loginUserInfo.authorityList.nodeList;
                return sortMenu(menus);
            }

        }
    },
    //主件被加载完成
    mounted: function () {
        //刷新时以当前路由做为tab加入tabs
        if (this.$route.meta.title != '首页') {
            this.$store.commit('add_tabs', {route: '/home', name: '首页'});
            this.$store.commit('set_active_index', this.$route.path);
        } else {
            this.$store.commit('set_active_index', '/home');
            this.$router.push('/home');
        }
    },
    //定义事件方法
    methods: {
        selectMenu(val) {
            console.log(val);
            this.$router.push({path: val});
        },
        goHome() {
            this.$router.push({path: '/home'})
        }
    }
}
</script>
