/**
 * 友盟统计
 */

window._czc = [];

export default {
    init: () => {
        let _div = document.createElement("div"),
            _script = document.createElement("script"),
            key = '1274683166',
            _src = `https://s22.cnzz.com/z_stat.php?id=${key}&web_id=${key}`
        _div.style.display = 'none';
        _script.setAttribute('src', _src)
        _div.appendChild(_script);
        document.body.appendChild(_div);
    }
}
