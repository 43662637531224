import Vue from 'vue';
import {formatDate, convertCurrency} from './Utils'
import dayjs from "dayjs";
import {seatModelList, channelList, productFormList, quotationTypeList} from "@/data/index";
import {findItem} from './common'
import decimal from 'decimal'

export const dateCus = function (date, fmt) {
    return formatDate(date, fmt)
}


export const dateFormat = function (date, fmt = 'YYYY-MM-DD HH:mm:ss', vfmt) {
    return dayjs(date, vfmt).format(fmt)
}

//价格
const currency = function (price = 0, ratio = 1, prefix = '') {
    if (!price)
        return prefix + '0.00'
    let value = price / ratio;  // 系数
    return prefix + (value % 1 === 0 ? value + '.00' : value)
};


//获取车座描述
const seatModelDesc = (value) => {
    return findItem(seatModelList, value, 'name')
}

//获取渠道描述
const getChannelDesc = (value) => {
    return findItem(channelList, Number(value), 'label', 'value')
}

//获取产品类型描述
const getProductType = (value) => {
    return findItem(productFormList, Number(value), 'name')
}

//获取报价公司类型
const getQuotationTypeList = (value) => {
    return findItem(quotationTypeList, value)
}

//格式化值和描述
export const formatDesc = (value, data, key = "value", name = "label") => {
    return (data.find((item) => item[key] === value || Number(item[key]) === Number(value)) || {})[name]
}

//格式化地址
export const formatAddressJson = (value, name = 'title') => {
    try {
        return JSON.parse(value)[name]
    } catch (err) {
        console.log(err);
    }
    return ''
}

/**
 * 将秒格式化为时分
 */
export const formatSecond = (value) => {
    if (!value)
        return 0;
    const h = Math.floor(value / (60 * 60));
    const m = Math.ceil(value % (60 * 60) / 60)
    return `${h ? h + '小时' : ''}${m}分钟`
}

/**
 * 数值想加减 防止小数时精度问题
 */

export const formatNumberAdd = (a, b) => {
    return decimal.add(a, b)
}

export default {
    install: () => {
        Vue.filter('dateCus', dateCus);
        Vue.filter('currency', currency);
        Vue.filter('seatModelDesc', seatModelDesc)
        Vue.filter('channelDesc', getChannelDesc)
        Vue.filter('productTypeDesc', getProductType)
        Vue.filter('quotationTypeDesc', getQuotationTypeList)
        Vue.filter('formatDesc', formatDesc)
        Vue.filter('formatAddressJson', formatAddressJson)
        Vue.filter('formatSecond', formatSecond)
        Vue.filter('dateFormat', dateFormat)
        Vue.filter('formatNumberAdd', formatNumberAdd)
        Vue.filter('chineseCurrency', convertCurrency)
    }
}
