const orderList = () => import('../pages/orderManage/orderList/index');
const userCarList = () => import('../pages/orderManage/userCarList/index');
const tripList = () => import('../pages/orderManage/tripList/index');
const orderImport = () => import('../pages/orderManage/orderImport/index');
const demand = () => import('../pages/orderManage/demandOrder/index');
const demandSheet = () => import('../pages/orderManage/demandSheetList/');
const demandSheetEdit = () => import('../pages/orderManage/demandSheetEdit/');
const insuranceList = () => import('../pages/orderManage/insuranceList/');
const touristCarOrder = () => import('../pages/orderManage/touristCarOrder/');
const orderGrab = () => import('../pages/orderManage/orderGrab/');
const orderComplaints = () => import('../pages/orderManage/orderComplaints/');
const orderComplaintsDetail = () => import('../pages/orderManage/orderComplaints/detail.vue');
const workOrder = () => import('../pages/workOrder/index.vue');
const workOrderDetail = () => import('../pages/workOrder/detail.vue');
const workOrderPenalty = () => import('../pages/workOrder/penalty.vue');
const timelyUseVehicle = () => import('../pages/orderManage/timelyUseVehicle/index.vue');


export default [
    {
        path: '/orderList', // 订单管理
        name: 'orderList',
        meta: {
            title: '订单列表',
            auth: true
        },
        component: orderList
    },
    {
        path: '/userCarList', // 用车订单
        name: 'userCarList',
        meta: {
            title: '用车订单',
            auth: true
        },
        component: userCarList,
    },
    {
        path: '/tripList', // 旅游订单
        name: 'tripList',
        meta: {
            title: '旅游订单',
            auth: true
        },
        component: tripList
    },
    {
        path: '/orderImport', // ota订单导入
        name: 'orderImport',
        meta: {
            title: '第三方订单导入'
        },
        component: orderImport,
    },
    {
        path: '/demand', // 客服下单
        name: 'demand',
        meta: {
            title: '客服下单',
            auth: true
        },
        component: demand,
    },
    {
        path: '/demandSheet', // 需求单
        name: 'demandSheet',
        meta: {
            title: '需求单',
            auth: true
        },
        component: demandSheet,
    },
    {
        path: '/demandSheetEdit/:orderId', // 需求单
        name: 'demandSheetEdit',
        meta: {
            title: '编辑需求单'
        },
        component: demandSheetEdit,
    },
    {
        path: '/insuranceList', // 保险订单
        name: 'insuranceList',
        meta: {
            title: '投保订单',
            auth: true
        },
        component: insuranceList,
    },
    {
        path: '/touristCarOrder', // 旅游用车订单列表
        name: 'touristCarOrder',
        meta: {
            title: '旅游用车订单',
            auth: true
        },
        component: touristCarOrder,
    },
    {
        path: '/orderGrab',
        name: 'orderGrab',
        meta: {
            title: '抢单大厅',
            auth: true
        },
        component: orderGrab,
    },
    {
        path: '/orderComplaints',
        name: 'orderComplaints',
        meta: {
            title: '投诉记录',
            auth: true
        },
        component: orderComplaints
    },
    {
        path: '/orderComplaintsDetail',
        name: 'orderComplaintsDetail',
        meta: {
            title: '投诉详情',
            auth: false
        },
        component: orderComplaintsDetail
    },
    {
        path: '/workOrder',
        name: 'workOrder',
        meta: {
            title: '事件记录',
            auth: true
        },
        component: workOrder
    },
    {
        path: '/workOrderDetail',
        name: 'workOrderDetail',
        meta: {
            title: '判罚详情',
            auth: false
        },
        component: workOrderDetail
    },
    {
        path: '/workOrderPenalty',
        name: 'workOrderPenalty',
        meta: {
            title: '判罚事件',
            auth: false
        },
        component: workOrderPenalty
    },
    {
        path: '/timelyUseVehicle',
        name: 'timelyUseVehicle',
        meta: {
            title: '接送服务',
            auth: false
        },
        component: timelyUseVehicle
    }
]
