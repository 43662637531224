<template>
    <div class="eTrip-section-cont" ref="content">
        <slot></slot>
    </div>
</template>

<script type="text/ecmascript-6">
    export default {
        name: 'sectionContent',
        activated() {
            this.routeName = this.$route.name;
            if (this.pages) {
                setTimeout(() => this.$refs.content.scrollTop = this.pages[this.routeName], 50)
            }
        },
        deactivated() {
            if (!this.pages)
                this.pages = {};
            //记录当前组件滚动 高度
            this.pages[this.routeName] = this.$refs.content.scrollTop
        }
    }
</script>