import {combinationForm} from "@/common/js/common";
import {message} from 'element-ui';
import Loading from '@/common/js/loading';
import {
    doPostBackendListInsuranceOrder,
    doBmsExportInsuranceOrder
} from "@/www/urls/insuranceListUrls";

import {productFormList, orderStatusList} from "@/data/index";
import dataInfo from "@/data/orderData/data";
// import service from "../../urls/settlementtUrls";
export const initForm = {
    orderNo: '',
    orgPlatOrderId:'',
    productStartCityId: '',
    createOrderTime: '',
    createOrderStartTime: '',
    createOrderEndTime: '',
    tripUserName:'',
    tripUserMobile:'',
    tripTime:'',
    tripStartTime:'',
    tripEndTime:'',
    driverName:'',
    driverMobile:'',
    vehicleNo:'',
    productName:'',
    insuranceTime: ''

};
export const modalData = {
    productFormList,
    orderStatusList,
    dataInfo
}




const state = () => ({
    list: [],
    page:{
        pageIndex: 1,
        total: 0,
        pageSize: 10
    },
    initForm,
    modalData


});

const mutations = {
    // form请求
    change_insurance_form(state, data) {
        state.initForm = {
            ...state.initForm,
            ...data
        }
    },
    // 保险订单列表
    change_insurance_list(state, data) {
        state.list = data
    },
    // 切换页码信息
    change_insurance_page(state, data) {
        state.page = {
            ...state.page,
            ...data
        }
    },


};

const actions = {
    async search_insurance_list({commit, state}, {pageIndex, form}){
        Loading.show();
        let initForm = form || state.initForm;
        let index = pageIndex || state.pageIndex;
        let data = {
            pageIndex: index,
            pageSize: state.page.pageSize,
            ...combinationForm(initForm)
        };
        const res = await doPostBackendListInsuranceOrder(data);
        if(res.success && res.data) {
            let orderList = res.data.data;
            orderList.forEach(item => {
                item.tripInfoDialog = 1;
                if (item.departure && item.departure.indexOf('address') != -1) {
                    item.departure = JSON.parse(item.departure);
                    item.isDeparture = true;
                }
                if (item.destination && item.destination.indexOf('address') != -1) {
                    item.destination = JSON.parse(item.destination);
                    item.isDestination = true;
                }
                state.modalData.productFormList.forEach(item1 => {
                    if (item.productForm == item1.value) {
                        item.productFormName = item1.name;
                    }
                })
                state.modalData.orderStatusList.forEach(os => {
                    // console.log(os.name);
                    if (item.orderStatus == os.value) item.orderStatusName = os.name;
                    if (item.orderStatus == 71) {
                        const cancelInfo = this.cacelStatusList.find(cs => {
                            return item.cancelStatus == cs.value;
                        })
                        item.orderStatusName = cancelInfo.name;
                    }
                })
                state.modalData.dataInfo.sourceTypeList.forEach(rs => {
                    if (item.sourceType == rs.value) item.sourceName = rs.name;
                })
                state.modalData.dataInfo.payTypeList.forEach(p => {
                    if (item.paymentType == p.value) item.payTypeName = p.name;
                })
                state.modalData.dataInfo.payChannelList.forEach(p => {
                    if (item.paymentChannel == p.value) {
                        item.payChannelName = p.name;
                        item.subjectName = p.subjectName
                    }
                })
                if (item.confirmPayment == 1)
                    item.operatorPaymentAmount = item.paymentAmount;
                else item.operatorPaymentAmount = item.receivableAmount;

                if (item.confirmActualSettle == 1)
                    item.operatorActualSettle = item.actualSettleAmount;
                else item.operatorActualSettle = item.settleAmount
            })
            commit('change_insurance_list', orderList);
            commit('change_insurance_page', {
                pageIndex: index,
                total: res.data.total
            });
            commit('change_insurance_form', initForm)

        } else message.error(res.errors[0].message);

        Loading.hide();
    },
    export_insurance_order: async ({state}, {form}) => {
        Loading.show();
        try {
            let initForm = form || state.initForm;
            let data = {
                ...combinationForm(initForm)
            }
            console.log(data, "000000000000");
            let res = await doBmsExportInsuranceOrder(data);
            if (res) {
                let d = new Date().getTime();
                let a = document.createElement('a');
                a.download = '投保订单导出表' + d + '.xls';
                a.href = window.URL.createObjectURL(res);
                a.click();

                Loading.hide();
                return true
            }
        } catch (e) {
            Loading.hide();
            message.error('下载失败')
        }
    },

};

export default {
    state,
    mutations,
    actions
}
