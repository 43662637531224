import conf from '@/config/'

const ls = 'sessionStorage' in window && window.sessionStorage;
const setObject = function (name, data) {
    if (!ls && !name)
        return false;
    name = conf.storagePrefix + name;
    if (data)
    {
        data = JSON.stringify(data);
        ls.setItem(name, data);
    }


}
const getObject = function (name) {
    if (!ls && !name)
        return false;
    else {
        name = conf.storagePrefix + name;
        return JSON.parse(ls.getItem(name));
    }
}
const set = function (name, data) {
    if (!ls && !name)
        return false;
    if (data) {
        name = conf.storagePrefix + name;
        ls.setItem(name, data);
    }

}
const get = function (name) {
    if (!ls && !name)
        return false;
    else {
        name = conf.storagePrefix + name;
        return ls.getItem(name);
    }
}
const remove = (name) => {
    name = conf.storagePrefix + name;
    ls && ls.removeItem(name);
}
const clearAll = () => {
    ls && ls.clear();
}
export default{
    setObject: setObject,
    getObject: getObject,
    set: set,
    get: get,
    removeItem: remove,
    clearAll: clearAll
}
