import Vue from 'vue';
import App from './App.vue';
import router from '../router/index';
import store from '../vuex/index';
import ElementUI from 'element-ui';
import global from '@/common/js/global';
import filter from '@/common/js/filter';
import directive from '@/common/js/directive';
import cnzz from '@/common/js/cnzz'
import sentry from '@/common/js/sentry'
import 'element-ui/lib/theme-chalk/index.css';
// import '@/assets/styles/element-variables.scss'
import Print from 'vue-print-nb'
import globalComponent from '@/components/index'


Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(filter);
Vue.use(directive);
Vue.use(sentry);
Vue.use(globalComponent)

Vue.use(Print);
global.init(Vue, store);

//友盟统计
cnzz.init();

new Vue({
    el: '#app',
    store,
    router,
    template: '<App/>',
    components: {App}
})

