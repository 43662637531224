import {combinationForm, findItem} from "@/common/js/common";
import {message} from 'element-ui';
import Loading from '@/common/js/loading';
import {
    rechargeAuditInfoPageList
} from "@/www/urls/openAuditUrls"
import {keepTwoDecimal, formatDate} from '@/common/js/Utils';
import {
    getSeatModel, rechargeProductTypes,
    // getSeatProductForm
} from "@/data/index";
import {
    getPayTypeLabel
} from "@/www/pages/vehicleService/components/data"

export const initForm = {
    rechargeRecordNo: '',
    transactionId: '',
    payStatus: '',
    auditName: '',
    createTime: [],
    createTimeStart: '',
    createTimeEnd: '',


    numberId: '',
    operatorId: '',
    supplierId: '',
    carType: 0,
    // productForm: 0,
    rechargeProductNo: ''
};
export const modalData = {
    getSeatModel,
    // getSeatProductForm
}

const state = () => ({
    list: [],
    page: {
        pageIndex: 1,
        total: 0,
        pageSize: 10
    },
    dialogInfo: {
        isShow: false,
    },
    auditAdoptDialog: {
        isShow: false,
    },

    isShowDialog: true,
    initForm,
    modalData
});

const mutations = {
    // form请求
    change_open_audit_form(state, data) {
        state.initForm = {
            ...state.initForm,
            ...data
        }
    },
    // 列表
    change_open_audit_list(state, data) {
        state.list = data
    },
    // 切换页码
    change_open_audit_page(state, data) {
        state.page = {
            ...state.page,
            ...data
        }
    },
    // 弹窗显示
    change_dialog_open_audit(state, data) {
        state.dialogInfo = data
    },
    // 审核通过不通过
    change_dialog_audit_adopt(state, data) {
        state.auditAdoptDialog = data
    },
};

const actions = {
    async search_open_audit_list({commit, state}, {pageIndex, form}) {
        Loading.show();
        let initForm = form || state.initForm;
        let index = pageIndex || state.pageIndex;
        let data = {
            pageIndex: index,
            pageSize: state.page.pageSize,
            ...combinationForm(initForm)
        };
        const res = await rechargeAuditInfoPageList(data);
        if (res.success && res.data) {
            let list = res.data.data;
            list.forEach(item => {
                console.log(item);
                item.rechargeTotalPrice = keepTwoDecimal(item.rechargeTotalPrice, 2);
                item.payPrice = keepTwoDecimal(item.payPrice, 2);
                // item.payStatusName = getAuditStatusLabel(item.payStatus).label;
                item.payTypeName = getPayTypeLabel(item.payType).label;
                item.createTime = formatDate(item.createTime, 'yyyy-MM-dd hh:mm')
                item.productName = findItem(rechargeProductTypes, item.productType);
            });
            commit('change_open_audit_list', list);
            commit('change_open_audit_page', {
                pageIndex: index,
                total: res.data.total
            });
            commit('change_open_audit_form', initForm)
        } else message.error(res.errors[0].message);

        Loading.hide()
    },
    handle_dialog_open_audit({commit}, data) {
        commit('change_dialog_open_audit', data)
    },
    handle_dialog_audit_adopt({commit}, data) {
        commit('change_dialog_audit_adopt', data)
    },
};

export default {
    state,
    mutations,
    actions
}

