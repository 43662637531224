const vehicleApply = () => import('../pages/vehicleService/vehicleApply/index');
const vehicleOpenList = () => import('../pages/vehicleService/vehicleOpenList/index');
const vehicleAuditList = () => import('../pages/vehicleService/vehicleAuditList/index');
const vehicleServeSee = () => import('../pages/vehicleService/vehicleServeSee/index');
const vehicleServeEdit = () => import('../pages/vehicleService/vehicleServeEdit/index');
const vehicleServeAudit = () => import('../pages/vehicleService/vehicleServeAudit/index');
const vehicleDriverList = () => import('../pages/vehicleService/vehicleDriverList/index');

export default [
    {
        path: '/vehicleApply', // 服务充值申请
        name: 'vehicleApply',
        meta: {
            title: '服务充值申请',
            auth: true
        },
        component: vehicleApply
    },
    {
        path: '/vehicleOpenList', // 车辆服务开通申请列表页
        name: 'vehicleOpenList',
        meta: {
            title: '服务充值申请列表',
            auth: false
        },
        component: vehicleOpenList
    },
    {
        path: '/vehicleAuditList', // 车辆服务审核列表
        name: 'vehicleAuditList',
        meta: {
            title: '服务审核列表',
            auth: true
        },
        component: vehicleAuditList
    },
    {
        path: '/vehicleServeSee/:auditId', // 车辆服务查看
        name: 'vehicleServeSee',
        meta: {
            title: '车辆服务查看'
        },
        component: vehicleServeSee
    },
    {
        path: '/vehicleServeEdit/:auditId/:type', // 车辆服务修改
        name: 'vehicleServeEdit',
        meta: {
            title: '车辆服务修改'
        },
        component: vehicleServeEdit
    },
    {
        path: '/vehicleServeAudit/:auditId', // 车辆服务审核
        name: 'vehicleServeAudit',
        meta: {
            title: '车辆服务审核'
        },
        component: vehicleServeAudit
    },
    {
        path: '/vehicleDriverList', // 车辆服务司机列表
        name: 'vehicleDriverList',
        meta: {
            title: '服务司机列表',
            auth: true
        },
        component: vehicleDriverList
    },
    {
        path: '/vehicleCarList', // 车辆服务司机列表
        name: 'vehicleCarList',
        meta: {
            title: '服务车辆列表',
            auth: true
        },
        component: vehicleDriverList
    },
]
