export default [
    {
        path: '/zslInvoiceList', // 中山陵发票
        name: 'zslInvoiceList',
        meta: {
            title: '中山陵发票列表',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/invoiceManage/invoiceList/index')), 'invoiceManage'),
    },
    {
        path: '/zslOrderList', // 司机列表
        name: 'zslOrderList',
        meta: {
            title: '中山陵订单列表',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/orderList/index')), 'zslManage'),
    },
    {
        path: '/zslAgencyList', // 司机列表
        name: 'zslAgencyList',
        meta: {
            title: '中山陵旅行社列表',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/agencyList/index')), 'zslManage'),
    },
    {
        path: '/zslTicketRecord', // 检票记录
        name: 'zslTicketRecord',
        meta: {
            title: '中山陵检票记录',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/ticketRecord/index')), 'zslManage'),
    },
    {
        path: '/zslRefundList', // 退款审核列表
        name: 'zslRefundList',
        meta: {
            title: '中山陵退款审核列表',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/refundList/index')), 'zslManage'),
    },
    {
        path: '/zslCheckManage', // 对账管理
        name: 'zslCheckManage',
        meta: {
            title: '中山陵对账管理',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/checkManage/index')), 'zslManage'),
    },
    {
        path: '/zslNoticeList', // 公告列表
        name: 'zslNoticeList',
        meta: {
            title: '中山陵公告列表',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/noticeList/index')), 'zslManage'),
    },
    {
        path: '/zslGuideList', // 导游列表
        name: 'zslGuideList',
        meta: {
            title: '导游列表',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/guideList/index')), 'zslManage'),
    },
    {
        path: '/zslGuideTourList/:id', // 导游列表-带团信息
        name: 'zslGuideTourList',
        meta: {
            title: '带团信息'
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/guideTourList/index')), 'zslManage'),
    },
    {
        path: '/zslBill', // 导游列表-对账管理 列表
        name: 'zslBill',
        meta: {
            title: '对账管理'
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/bill/list/index.vue')), 'zslManage'),
    },
    {
        path: '/zslBillDetail/:id', // 导游列表-对账管理 列表
        name: 'zslBillDetail',
        meta: {
            title: '对账管理'
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/bill/detail/index.vue')), 'zslManage'),
    },
    {
        path: '/zslReportForm', // 统计报表
        name: 'zslReportForm',
        meta: {
            title: '统计报表',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/reportForm/index.vue')), 'zslManage'),
    },
    {
        path: '/zslDataDetails', // 数据大盘-订单数据明细
        name: 'zslDataDetails',
        meta: {
            title: '订单数据明细'
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/dataDetails/index')), 'zslManage'),
    },
    {
        path: '/flowRecord', // 流水记录
        name: 'flowRecord',
        meta: {
            title: '流水记录',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/flowRecord/index.vue')), 'zslManage'),
    },
    {
        path: '/settlementRecord', // 结汇记录
        name: 'settlementRecord',
        meta: {
            title: '结汇记录',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/bill/settlementRecord/index.vue')), 'zslManage'),
    },
    {
        path: '/creditPoints', // 信用积分
        name: 'creditPoints',
        meta: {
            title: '信用与积分',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/creditAndPoints/index.vue')), 'zslManage'),
    },
    {
        path: '/ruleConfiguration', // 信用积分
        name: 'ruleConfiguration',
        meta: {
            title: '规则配置',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/ruleConfiguration/index.vue')), 'zslManage'),
    },
    {
        path: '/bookingAndModificationRules',
        name: 'bookingAndModificationRules',
        meta: {
            title: '预订和修改规则编辑',
            auth: false
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/ruleConfiguration/bookingAndModificationRules.vue')), 'zslManage'),
    },
    {
        path: '/unfulfilledRules', // 信用积分
        name: 'unfulfilledRules',
        meta: {
            title: '未履约规则编辑',
            auth: false
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/ruleConfiguration/unfulfilledRules.vue')), 'zslManage'),
    },
    {
        path: '/integralRule', // 信用积分
        name: 'integralRule',
        meta: {
            title: '积分规则编辑',
            auth: false
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/ruleConfiguration/integralRule.vue')), 'zslManage'),
    },
    {
        path: '/interventionOrders', // 客服介入
        name: 'interventionOrders',
        meta: {
            title: '客服介入',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/interventionOrders/index.vue')), 'zslManage'),
    },
    {
        path: '/interventionOrderDetail',
        name: 'interventionOrderDetail',
        meta: {
            title: '申请详情',
            auth: false
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/interventionOrders/detail.vue')), 'zslManage'),
    },
    {
        path: '/companyReview',
        name: 'companyReview',
        meta: {
            title: '机构初审',
            auth: true
        },
        component: r => require.ensure([], () => r(require('../pages/zslManage/companyReview/index.vue')), 'zslManage'),
    }
]
