<template>
    <el-button type="primary"
               :loading="loading"
               v-bind="$attrs"
               :disabled="getDisabled"
               @click="handleGetCode">
        {{ time ? `${time}s` : '获取验证码' }}
    </el-button>
</template>

<script>
import {isCellphone} from "@/common/js/Utils";
import localStorage from "@/common/js/localStorage";
import moment from "moment";
export default {
    name: "CountDownButton",
    data () {
        return {
            loading: false,
            time: null,
            disabledButton: true
        }
    },
    props: {
        api: {
            type: Function,
            default: null
        },
        params: {
            type: Object,
            default: null
        }
    },
    beforeDestroy () {
        clearInterval(this._timer)
    },
    computed: {
        getDisabled () {
            return this.disabledButton || this.$attrs.disabled
        }
    },
    mounted () {
        const sendCode = localStorage.getObject('sendCode');
        if (sendCode && sendCode.type && sendCode.time) {
            const n = moment().diff(moment(sendCode.time), 'seconds');
            n < 60 && this.startTimer(60 - n);
        }
    },
    methods: {
        async handleGetCode () {
            if (this.api && !this.getDisabled) {
                this.loading = true;
                this.disabledButton = true;
                const ret = await this.api(this.params)
                if (ret.success && ret.data) {
                    localStorage.setObject('sendCode', {
                        mobile: this.params.mobile,
                        type: this.params.checkCodeType,
                        time: +new Date()
                    })
                    this.startTimer(60);
                    this.$message.success('验证码已发送到您的手机～')
                } else {
                    this.disabledButton = false
                    this.$message.error(ret.errors ? ret.errors[0].message : '获取验证码失败～');
                }
                this.loading = false
            }
        },
        startTimer (n) {
            this.disabledButton = true;
            clearInterval(this._timer);
            this.time = n;
            this._timer = setInterval(() => {
                this.time -= 1;
                this.disabledButton = true;
                if (this.time === 0) {
                    clearInterval(this._timer)
                    this.disabledButton = false;
                    this.time = null;
                }
            }, 1000)
        }
    },
    watch: {
        params: {
            handler () {
                if (!this.time)
                    this.disabledButton = !isCellphone(this.params.mobile);
            },
            immediate: true,
            deep: true
        }
    }
}
</script>

<style scoped>

</style>