/**
* Created by hansxing on 2018/8/20.
*/
<style lang="scss">
.login-register-idx {
    /*z-index: 89 !important;*/
    &.login-register-idxTds {
        /*z-index: 2020 !important;*/
    }
}

.login-register-cont {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #fff;
    position: absolute;
    margin: 0 auto !important;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.login-register-cont {
    z-index: 9;

    .el-dialog__header {
        padding: 0;
        display: none;
    }

    .el-dialog__body {
        padding: 40px 30px;
    }

    .login-register-close {
        outline: none;
        border: 0;
        padding: 0;
        border-radius: 50%;
        position: absolute;
        right: 20px;
        top: -60px;
        background: transparent;
        color: #ffffff;

        &.el-button:hover {
            color: rgba(255, 255, 255, 0.8);
        }

        i {
            font-size: 28px;
        }

        &:before {
            content: '';
            display: inline-block;
            width: 2px;
            height: 36px;
            position: absolute;
            bottom: -34px;
            left: 50%;
            margin-left: -1px;
            background: #fff;
        }
    }

    .login-register-section {

    }

    .home-logo-cont {
        text-align: center;
        position: absolute;
        top: -120px;
        left: 0;
        right: 0;

        img {
            width: 120px;
        }

        h2 {
            margin-top: 6px;
            color: #4D4D4D;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 5px;

        }
    }

}

</style>
<template>
    <div>
        <el-dialog
            class="login-register-idx"
            :class="{'login-register-idxTds':projectName!='bms'}"
            custom-class="login-register-cont"
            :visible.sync="loginOrRegister.show"
            width="416px"
            :modal="projectName!='bms'"
            center
            :close-on-click-modal="false"
            :show-close="false">
            <el-button class="login-register-close"
                       v-if="projectName!='bms'"
                       @click="handleClose">
                <i class="el-icon-circle-close"></i>
            </el-button>
            <div class="home-logo-cont" v-if="projectName == 'bms'" :style="logoStyle">
                <img :src="logo"/>
                <h2>后台管理系统</h2>
            </div>
            <div class="login-register-section">
                <login-info v-if="loginOrRegister.type===0"></login-info>
                <register-info v-if="loginOrRegister.type===1"></register-info>
                <forget-password v-if="loginOrRegister.type===2"></forget-password>
            </div>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
import loginInfo from '../components/loginInfo/index.vue'
import registerInfo from '../components/registerInfo/index.vue'
import forgetPassword from '../components/forgetPassword/index.vue'
import {backendGetUserInfo, tdsGetUserInfo} from '../service'

export default {
    //定义模版数据
    data() {
        return {}
    },
    components: {
        loginInfo,
        registerInfo,
        forgetPassword
    },
    //计算属性
    computed: {
        loginOrRegister() {
            return this.$store.state.loginOrRegister;
        },
        logo() {
            return process.env_project.SYSTEM_NAME === 'zsl' ? require('../../../assets/images/logo-zsl.png') :
                require('../../../assets/images/logo_full.png')
        },
        logoStyle() {
            return process.env_project.SYSTEM_NAME === 'zsl' ? {top: '-170px'} : null
        }
    },
    //主件被加载完成
    mounted: function () {
        this.getUserInfo()
    },
    //定义事件方法
    methods: {
        async getUserInfo() {
            let ret = null;
            //请求用户信息
            switch (this.projectName) {
                case 'bms':
                    ret = await backendGetUserInfo(this);
                    break;
                case 'tds':
                    ret = await tdsGetUserInfo(this);
                    break;
                default:
                    ret = null;
            }
            if (ret.success) {
                this.$store.dispatch("CHANGE_USER_LOGIN_INFO", ret.data);
                this.localStorage.setObject("loginUserInfo", ret.data);
            } else if (ret.errors && ret.errors.lenght > 0)
                this.$message.error(ret.errors[0].message)
        },
        handleClose() {
            this.$store.dispatch("CHANGE_LOGIN_OR_REGISTER", {
                show: false,
                type: 0
            })
        }
    }

}
</script>

