export const rechargeTypeList = [
    {
        value: 0,
        label: '包月'
    },
    {
        value: 1,
        label: '包季'
    },
    {
        value: 2,
        label: '包年'
    },
    {
        value: 3,
        label: '包日'
    },
];  // 充值类型

export const enableStatusList = [
    {
        value: 0,
        label: '已启用'
    },
    {
        value: 1,
        label: '已封停'
    },
];  // 状态

export const deadlineList = [
    {
        value: 0,
        label: '7天',
        time: 7 * 24 * 60 * 60 * 1000
    },
    {
        value: 1,
        label: '15天',
        time: 15 * 24 * 60 * 60 * 1000
    },
    {
        value: 2,
        label: '30天',
        time: 30 * 24 * 60 * 60 * 1000
    },
];  // 截止时间
