"use strict";

import axios from 'axios';
import qs from 'qs';


const key = 'd29fa71d7ad0b97a3c640a1ff5201a02';


export default async (url, data = {}, method = 'GET') => {

    data.key = key;

    let config = {
        method: method,
        url: 'https://tsapi.amap.com/v1/track/' + url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    }

    if (method === 'GET')
        config.params = data;

    if (method === 'POST')
        config.data = qs.stringify(data);

    // 发送 POST 请求
    const ret = await axios(config);


    if (ret.status === 200)
        return ret.data;
};
