const infoPrint = () => import('../pages/tools/infoPrint/index');

export default [
    {
        path: '/infoPrint/:type', // 打印 type: 0司机 1车辆
        name: 'infoPrint',
        meta: {
            title: '打印'
        },
        component: infoPrint
    },
]
