import {Loading} from 'element-ui'

let loadingService = '';

const show = function (options) {
    if (loadingService)
        loadingService.close();
    loadingService = Loading.service(options);
}

const hide = function () {
    setTimeout(() => {
        "use strict";
        loadingService.close()
    }, 100)
}

export default {
    show,
    hide
}
