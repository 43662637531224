import http from '@/common/js/http'

// 新增客服时间
const insertProductQuoteCustomService = async (form) => {
    return await http('/galaxyQuoteConfigurationApi/insertProductQuoteCustomService', form, "POST");
};
// 客服时间详情
const productQuoteCustomServiceDetail = async (id) => {
    return await http(`/galaxyQuoteConfigurationApi/productQuoteCustomServiceDetail/${id}`);
};
// 客服时间列表
const productQuoteCustomServicePageList = async (form) => {
    return await http('/galaxyQuoteConfigurationApi/productQuoteCustomServicePageList', form, "POST");
};
// 更新客服时间
const updateProductQuoteCustomService = async (form) => {
    return await http('/galaxyQuoteConfigurationApi/updateProductQuoteCustomService', form, "POST");
};
// 新增订单联系人信息
const insertProductOrderConnectInfo = async (form) => {
    return await http('/galaxyQuoteConfigurationApi/insertProductOrderConnectInfo', form, "POST");
};
// 订单联系人信息详情
const productOrderConnectInfoDetail = async (id) => {
    return await http(`/galaxyQuoteConfigurationApi/productOrderConnectInfoDetail/${id}`);
};
// 订单联系人信息列表
const productOrderConnectInfoPageList = async (form) => {
    return await http('/galaxyQuoteConfigurationApi/productOrderConnectInfoPageList', form, "POST");
};
// 更新订单联系人信息
const updateProductOrderConnectInfo = async (form) => {
    return await http('/galaxyQuoteConfigurationApi/updateProductOrderConnectInfo', form, "POST");
};
// 新增应单人信息
const insertProductReceiverInfo = async (form) => {
    return await http('/galaxyQuoteConfigurationApi/insertProductReceiverInfo', form, "POST");
};
// 应单人信息详情
const productReceiverInfoDetail = async (id) => {
    return await http(`/galaxyQuoteConfigurationApi/productReceiverInfoDetail/${id}`);
};
// 应单人信息列表
const productReceiverInfoPageList = async (form) => {
    return await http('/galaxyQuoteConfigurationApi/productReceiverInfoPageList', form, "POST");
};
// 更新应单人信息
const updateProductReceiverInfo = async (form) => {
    return await http('/galaxyQuoteConfigurationApi/updateProductReceiverInfo', form, "POST");
};
// 总部新增报价工具
export const insertQuoteRuleProductBatch = async (form) => {
    return await http('/galaxyQuote/insertQuoteRuleProductBatch', form, "POST");
};
// 查询订单联系人，下拉列表
export const getProductOrderConnectInfoList = async (form) => {
    return await http('/galaxyQuoteConfigurationApi/productOrderConnectInfoList', form, "POST");
};
// 查询客服时间，下拉列表
export const getProductQuoteCustomServiceList = async (form) => {
    return await http('/galaxyQuoteConfigurationApi/productQuoteCustomServiceList', form, "POST");
};
// 查询应单人，下拉列表
export const getProductReceiverInfoList = async (form) => {
    return await http('/galaxyQuoteConfigurationApi/productReceiverInfoList', form, "POST");
};

// 获取报价列表
export const getQuoteRuleList = async (form) => {
    return await http('/galaxyQuote/pageListQuoteRule', form, "POST");
};

// 导出报价工具
export const exportQuoteRuleList = async (form) => {
    return await http('/galaxyQuote/exportQuoteRuleList', form, 'POST', true, 'blob');
};
// 导入特定日期加价
export const importQuoteSedDateExcel = async (form) => {
    return await http('/galaxyQuote/importQuoteSedDateExcel', form, 'POST');
};

// 更新报价的状态
export const updateQuoteRuleStatus = async (form) => {
    return await http('/galaxyQuote/updateQuoteRuleStatus', form, 'POST');
};
// 复制
export const copyQuoteRuleProduct = async (id) => {
    return await http(`/galaxyQuote/copyQuoteRuleProduct/${id}`, {}, 'POST');
};
// 删除
export const deleteQuoteRuleProductBatch = async (form) => {
    return await http('/galaxyQuote/deleteQuoteRuleProductBatch', form, 'POST');
};

//获取围栏
export const getOperatorAddressApi = async (form) => {
    return await http('/galaxyOperatorAddress/getOperatorAddress', form, 'POST')
}
//新增围栏
export const addOperatorAddressApi = async (form) => {
    return await http('/galaxyOperatorAddress/addOperatorAddress', form, 'POST')
}
//更新围栏
export const updateOperatorAddressApi = async (form) => {
    return await http('/galaxyOperatorAddress/updateOperatorAddress', form, 'POST')
}

//获取携程高低价屏蔽
export const getCtripPriceLimitApi = async (form) => {
    return await http('/galaxyQuote/getCtripPriceLimit', form, 'POST')
}

//获取报价库存
export const getNoInventoryByDayApi = async (form) => {
    return await http('/galaxyQuote/getNoInventoryByDay', form, 'POST')
}

export default {
    insertProductQuoteCustomService,
    productQuoteCustomServiceDetail,
    productQuoteCustomServicePageList,
    updateProductQuoteCustomService,
    insertProductOrderConnectInfo,
    productOrderConnectInfoDetail,
    productOrderConnectInfoPageList,
    updateProductOrderConnectInfo,
    insertProductReceiverInfo,
    productReceiverInfoDetail,
    productReceiverInfoPageList,
    updateProductReceiverInfo,
    insertQuoteRuleProductBatch,
    getOperatorAddressApi,
    addOperatorAddressApi
}
