import Vue from 'vue'
import Vuex from 'vuex'

import cState from '../../common/vuex/state'
import cActions from '../../common/vuex/actions'
import cMutations from '../../common/vuex/mutations'

import state from './state'
import actions from './actions'
import mutations from './mutations'


import settlement from './modules/settleList'
import singleSetting from './modules/singleSetting'
import quotationToolList from './modules/quotationToolList'
import insuranceList from './modules/insuranceList'
import rechargeProducts from './modules/rechargeProducts'
import openAudit from './modules/openAudit'
import vehicleDriver from './modules/vehicleDriver'

//  自定义store modules导入

global.hub = new Vue();

Vue.use(Vuex)

Object.assign(state, cState);
Object.assign(mutations, cMutations);
Object.assign(actions, cActions);

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    state,
    actions,
    mutations,
    modules: {
        settlement,
        singleSetting,
        quotationToolList,
        insuranceList,
        rechargeProducts,
        openAudit,
        vehicleDriver
    },
    trict: debug
    // plugins: debug ? [createLogger()] : []
})
