import cookieStorage from '../js/cookieStorage'
import sessionStorage from '../js/sessionStorage'
import localStorage from "../js/localStorage";

export const beforeEach = function (to, from, next, store) {
    //设置标题
    let showBack = to.meta.showBack;
    if (showBack === undefined)
        showBack = true;
    store.dispatch("CHANGE_VIEW_TITLE", {
        showBack,
        title: to.meta.title || '司导端'
    });
    if (!to.meta.free && (!cookieStorage.get("eTrip_token") || !localStorage.getObject("loginUserInfo"))) {
        store.dispatch("CHANGE_USER_LOGIN_INFO", {});
        localStorage.removeItem("loginUserInfo");
        sessionStorage.set("nextPath", to.fullPath);
        store.dispatch('CHANGE_LOGIN_OR_REGISTER', {
            show: true,
            type: 0
        });

        switch (process.env_project.MODULE_ENV) {
            case 'bms':
                next('/home');
                return;
            case 'tds':
                break;
            case 'driver_h5':
            case 'bms_h5':
                next('/login');
                return;
        }
    }
    next();
}

export const afterEach = () => {

}
