import http from '@/common/js/http'


// 司机充值审核信息列表
export const rechargeAuditInfoPageList = async (form) => {
    return await http('/galaxyRechargeAuditInfoApi/rechargeAuditInfoPageList', form, "POST");
};
// 充值审核信息日志
export const rechargeLogDtoList = async (form) => {
    return await http('/galaxyRechargeAuditInfoApi/rechargeLogDtoList', form, "POST");
};
// 审核司机审核信息
export const rechargeAuditInfoAudit = async (form) => {
    return await http('/galaxyRechargeAuditInfoApi/rechargeAuditInfoAudit', form, "POST");
};
// 充值审核信息详情
export const rechargeAuditInfoDetail = async (id) => {
    return await http(`/galaxyRechargeAuditInfoApi/rechargeAuditInfoDetail/${id}`);
};
// 更新充值审核信息
export const rechargeAuditInfoUpdate = async (form) => {
    return await http('/galaxyRechargeAuditInfoApi/rechargeAuditInfoUpdate', form, "POST");
};
// 导出服务审核列表
export const rechargeAuditInfoListExport = async (form) => {
    return await http('/galaxyRechargeAuditInfoApi/rechargeAuditInfoListExport', form, 'POST', true, 'blob');
};
