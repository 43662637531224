import types from './mutation-types'

export default {
    [types.CHANGE_LOGIN_OR_REGISTER]: ({commit}, opt) => {
        commit(types.CHANGE_LOGIN_OR_REGISTER, opt)
    },
    [types.CHANGE_USER_INFO]: ({commit}, userInfo) => {
        commit(types.CHANGE_USER_INFO, userInfo)
    },
    [types.CHANGE_USER_LOGIN_INFO]: ({commit}, loginInfo) => {
        commit(types.CHANGE_USER_LOGIN_INFO, loginInfo)
    },
    [types.CHANGE_LOCATION]: ({commit}, service) => {
        commit(types.CHANGE_LOCATION, service)
    },
    [types.CHANGE_VIEW_TITLE]: ({commit}, viewTitle) => {
        commit(types.CHANGE_VIEW_TITLE, viewTitle)
    }
}
