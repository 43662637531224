// 第三方结算列表
const paySettlementPageList = async (_this, form) => {
    return await _this.http('/userDriverSettlement/paySettlementPageList', form, "POST");
};
// 删除结算信息 列表
const deletePaySettlement = async (_this, form) => {
    return await _this.http('/userDriverSettlement/deletePaySettlement', form, "POST");
};

// 新增结算信息
const createPaySettlementOnly = async (_this, form) => {
    return await _this.http('/userDriverSettlement/createPaySettlementOnly', form, "POST");
};

// 结算详情
const paySettlementDetail = async (_this, paySettlementId) => {
    return await _this.http(`/userDriverSettlement/paySettlementDetail/${paySettlementId}`);
};
// 结算状态修改为已结算
const updatePaySettlementStatus = async (_this, form) => {
    return await _this.http('/userDriverSettlement/updatePaySettlementStatus', form, "POST");
};
// 司机结算信息列表
const userDriverSettlementList = async (_this, form) => {
    return await _this.http('/userDriverSettlement/userDriverSettlementList', form, "POST");
};

// 更新结算信息+添加详情  新增结算明细  保存
const createPaySettlement = async (_this, form) => {
    return await _this.http('/userDriverSettlement/createPaySettlement', form, "POST");
};
// 后台司机结算信息分页列表
const userDriverSettlementPageList = async (_this, form) => {
    return await _this.http('/userDriverSettlement/userDriverSettlementPageList', form, "POST");
};
// 删除司机结算信息
const deleteUserDriverSettlement = async (_this, form) => {
    return await _this.http('/userDriverSettlement/deleteUserDriverSettlement', form, "POST");
};
// 编辑司机结算信息
const updateUserDriverSettlement = async (_this, form) => {
    return await _this.http('/userDriverSettlement/updateUserDriverSettlement', form, "POST");
};
//删除结算信息详情
const deletePaySettlementDetail = async (_this, form) => {
    return await _this.http('/userDriverSettlement/deletePaySettlementDetail', form, "POST");
};
//结算信息状态数量
const getSettlementStatusCount = async (_this) => {
    return await _this.http('/userDriverSettlement/getSettlementStatusCount');
};
//导出司机结算信息
const exportDriverSettle = async (_this, form) => {
    return await _this.http('/userDriverSettlement/exportUserDriverSettlement', form, 'POST', true, 'blob');
};
//导出结算列表
const exportPaySettle = async (_this, form) => {
    return await _this.http('/userDriverSettlement/exportPaySettlementList', form, 'POST', true, 'blob');
};


export default {
    paySettlementPageList,
    deletePaySettlement,
    createPaySettlementOnly,
    paySettlementDetail,
    updatePaySettlementStatus,
    userDriverSettlementList,
    createPaySettlement,
    userDriverSettlementPageList,
    deleteUserDriverSettlement,
    updateUserDriverSettlement,
    deletePaySettlementDetail,
    getSettlementStatusCount,
    exportDriverSettle,
    exportPaySettle
}
