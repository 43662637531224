export default {
    editProduct: ({commit}, editProduct) => {
        commit('editProduct', editProduct)
    },
    addProduct: ({commit}, addProduct) => {
        commit('addProduct', addProduct)
    },
    outsideFramework: ({commit}, bool) => {
        commit('outsideFramework', bool)
    }
}
