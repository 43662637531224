export default {
    options: [{route: '/home', name: '首页'}],
    activeIndex: '/',
    addProduct: {
        productId: false,
        resourceId: -1,
        productOperatorId: false
    },
    editProduct: {
        productId: false,
        resourceId: -1,
        productOperatorId: false
    },
    outsideFramework: false,//是否离开项目框架

}
