import {combinationForm, findItem} from "@/common/js/common";
import {message} from 'element-ui';
import Loading from '@/common/js/loading';
import {
    rechargeDriverInfoList,
    rechargeDriverInfoUpdate,
    rechargeDriverInfoEnable,
    rechargeLogDtoList
} from "@/www/urls/vehicleDriverUrls"
import {getSeatModel, rechargeProductTypes} from "@/data/index";
import {formatDesc, dateCus} from "@/common/js/filter";
import {rechargeTypeList, enableStatusList, deadlineList} from '@/data/vehicleDriverList'

export const initForm = {
    driverName: null,
    operatorId: null,
    supplierId: null,
    isDelete: null,
    validityEndTime: null
};

const state = () => ({
    rechargeTypeList,
    enableStatusList,
    deadlineList,
    list: [],
    productType: 0,//0司机 1 车辆
    page: {
        pageIndex: 1,
        total: 0,
        pageSize: 10
    },
    form: {
        ...initForm
    },
    carList: [],
    carPage: {
        pageIndex: 1,
        total: 0,
        pageSize: 10
    },
    carForm: {
        ...initForm,
    },
    logs: []
});

const mutations = {
    // form请求
    change_vehicle_driver_form(state, data) {
        if (state.productType === 0)
            state.form = {
                ...state.form,
                ...data
            }
        else
            state.carForm = {
                ...state.form,
                ...data
            }
    },
    // 列表
    set_vehicle_driver_list(state, data) {
        console.log(data);
        if (state.productType === 0)
            state.list = data
        else
            state.carList = data;
    },
    // 切换页码
    change_vehicle_driver_page(state, data) {
        if (state.productType === 0)
            state.page = {
                ...state.page,
                ...data
            }
        else state.carPage = {
            ...state.page,
            ...data
        }
    },
    rest_vehicle_driver_form(state) {
        if (state.productType === 0)
            state.form = {...initForm}
        else state.carForm = {...initForm}
    },
    change_vehicle_driver_list(state, id, data) {
        let list = state.productType === 0 ? state.list : state.carList;
        const index = list.findIndex((item) => item[state.productType === 0 ? 'driverId' : 'carId'] === id)
        list[index] = {
            ...list[index],
            ...data
        }
        if (state.productType === 0)
            state.list = [...list];
        else state.carList = [...list]
    },
    //设置log
    set_vehicle_driver_logs_list(state, data) {
        state.logs = data;
    },
    change_vehicle_driver_list_productType(state, data) {
        state.productType = data;
    }

};

const actions = {
    //获取列表数据
    async search_vehicle_driver_list({commit, state}, {pageIndex, form = state.form}) {
        Loading.show();
        let index = pageIndex || state.pageIndex;
        let data = {
            pageIndex: index,
            pageSize: state.page.pageSize,
            ...combinationForm(form),
            productType: state.productType
        };
        if (data.validityEndTime)
            data.validityEndTime = +new Date() + findItem(deadlineList, form.validityEndTime, 'time')

        const res = await rechargeDriverInfoList(data);
        if (res.success && res.data) {
            let list = res.data.data;
            list.forEach(item => {
                item.driver = `${item.driverName}(${item.mobile})`
                item.carTypeName = getSeatModel(item.carType).name;
                item.rechargeTypeName = formatDesc(item.rechargeType, rechargeTypeList)
                item.statusName = formatDesc(item.isDelete, enableStatusList)
                item.productName = findItem(rechargeProductTypes, item.productType);
                item.validityStartTime = dateCus(item.validityStartTime, 'yyyy-MM-dd hh:mm')
                item.validityEndTime = dateCus(item.validityEndTime, 'yyyy-MM-dd hh:mm')
            });
            commit('set_vehicle_driver_list', res.data.data);
            commit('change_vehicle_driver_page', {
                pageIndex: index,
                total: res.data.total
            });
            commit('change_vehicle_driver_form', form)
        } else message.error(res.errors[0].message);
        Loading.hide()
    },
    //获取日志信息
    async get_recharge_log_list({commit}, form) {
        commit('set_vehicle_driver_logs_list', [])
        const ret = await rechargeLogDtoList(form)
        let list = []
        if (ret.success && ret.data)
            list = ret.data;
        commit('set_vehicle_driver_logs_list', list)
    },
    //更新时间
    async update_recharge_driver_info({state, commit}, form) {
        Loading.show()
        const ret = await rechargeDriverInfoUpdate(form);
        Loading.hide();
        if (ret.success && ret.data) {
            commit('change_vehicle_driver_list', state.productType === 0 ? form.driverId : form.carId, {validityEndTime: dateCus(form.validityEndTime, 'yyyy-MM-dd hh:mm')})
            return true
        } else return false
    },
    //启停用
    async enable_recharge_driver_info({state, commit}, form) {
        Loading.show()
        const ret = await rechargeDriverInfoEnable(form);
        Loading.hide();
        if (ret.success && ret.data) {
            commit('change_vehicle_driver_list', state.productType === 0 ? form.driverId : form.carId, form)
            return true
        } else return false
    }
};

const getters = {
    getVehicleDriverList(state) {
        console.log(state);
        return state.productType === 0 ? state.list : state.carList
    },
    getVehicleDriverPage(state) {
        return state.productType === 0 ? state.page : state.carPage
    },
    getVehicleDriverForm(state) {
        return state.productType === 0 ? state.form : state.carForm
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}


