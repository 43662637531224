export function sortTree(tree, sortKey, children = 'children') {
    // 递归处理每个节点
    function processNode(node) {
        // 对子节点数组进行排序
        if (node[children]) {
            node[children].sort((a, b) => a[sortKey] - b[sortKey]);
            // 递归处理子节点
            node[children].forEach(processNode);
        }
    }

    // 从根节点开始处理
    processNode(tree);
    return tree;
}
