import http from '@/common/js/http'
//订单列表
const orderListHttp = async (_this, form, url) => {
    return await _this.http(url, form, "POST");
};
// //订单状态数据
// const getOrderStatusCount = async (_this, form) => {
//     return await _this.http('/galaxyOrder/doGetOrderStatusCountByLoginUserType', form, "POST");
// };
//根据id获取订单状态数量
const getOrderStatusCount = async (_this, form, type) => {
    let url = type === 1 ? 'GetOrderStatusCountNotUseCar' : 'getOrderStatusCountByOperatorAndSupplierId';
    return await _this.http(`/galaxyOrder/${url}`, form, "POST", false);
}
//客服信息列表
const OrderRemarkList = async (_this, form) => {
    return await _this.http('/galaxyOrder/OrderRemarkList', form, "POST");
};
//添加客服信息
const addOrderRemark = async (_this, form) => {
    return await _this.http('/galaxyOrder/addOrderRemark', form, "POST");
};
//分配司导
const distributionGuider = async (_this, form) => {
    return await _this.http('/galaxyOrder/distributionGuider', form, "POST");
};
//旅游订单分配司导/重新分配司导
const touristDistributionGuider = async (_this, form) => {
    return await _this.http('/galaxyOrder/doDistributionOrderTouristGuider', form, "POST");
};
//分配供应商
const distSupplier = async (_this, form) => {
    return await _this.http('/galaxyOrder/distributionGuider', form, "POST");
};
//订单变更
const orderChangeHttp = async (_this, form) => {
    return await _this.http('/galaxyOrder/modifyOrder', form, "POST");
};
//撤销订单变更
const withdrawOrderModification = async (_this, form) => {
    return await _this.http('/galaxyOrder/withdrawOrderModification', form, "POST");
};
//客服撤回待供应商确认的订单取消操作
const withdrawOrderCancellation = async (_this, form) => {
    return await _this.http('/galaxyOrder/withdrawOrderCancellation', form, "POST");
};
//客服拒绝订单取消申请
const rejectCancelApplication = async (_this, form) => {
    return await _this.http('/galaxyOrder/rejectCancelApplication', form, "POST");
};
//供应商接受订单取消
const acceptOrderCancellation = async (_this, form) => {
    return await _this.http('/galaxyOrder/acceptOrderCancellation', form, "POST");
};
//供应商接受订单修改
const acceptOrderModification = async (_this, form) => {
    return await _this.http('/galaxyOrder/acceptOrderModification', form, "POST");
};
//客服处理订单申请或发起取消订单操作
const cancelOrder = async (_this, form) => {
    return await _this.http('/galaxyOrder/cancelOrder', form, "POST");
};
//获取供应商
const getSupplierInfoByOperatorId = async (_this, form) => {
    return await _this.http('/galaxySupplier/doGalaxyGetSupplierInfoByOperatorId', form, "POST", false);
}
// //分配供应商
const addSupplier = async (_this, form) => {
    return await _this.http('/galaxyOrder/addSupplier', form, "POST", false);
}
// //重新分配供应商
const reallocateSupplier = async (_this, form) => {
    return await _this.http('/galaxyOrder/reallocateSupplier', form, "POST", false);
}
//运营商拒绝订单
const operatorRefuse = async (_this, form) => {
    return await _this.http('/galaxyOrder/operatorRefuse', form, "POST", false);
}
//供应商确认订单
const supplierConfirm = async (_this, form) => {
    return await _this.http('/galaxyOrder/supplierConfirm', form, "POST", false);
}
//供应商拒绝订单
const supplierRefuse = async (_this, form) => {
    return await _this.http('/galaxyOrder/supplierRefuse', form, "POST", false);
}
//重发确认短信给出行人
const operatorShortMess = async (_this, form) => {
    return await _this.http('/galaxyOrder/resendMessageToTripUser', form, "POST", false);
}
//供应商修改司导价格
const supplierUpdatePrice = async (_this, form) => {
    return await _this.http('/galaxyOrder/updateOrderDriverPrice', form, "POST", false);
}
//取消司导分配
const cancelDistributeGuider = async (_this, form) => {
    return await _this.http('/galaxyOrder/cancelDistributeGuider', form, "POST", false);
}
//总部获取供应商
export const getAllSupplier = async (_this, form) => {
    return await _this.http('/galaxySupplier/doGalaxyGetSupplierList', form, "POST");
};
//订单状态变更为新订待分配(拉回总部)
const getRestoreAssignSupplier = async (_this, form) => {
    return await _this.http('/galaxyOrder/updateOrderStatusToAssignSupplier', form, "POST");
};

/*
* 完成携程外派司机订单
* 携程(sourceType值12)  待出行订单(OrderStatus值41) 外派司机(driverGuiderNo为null)
 */
const finishBackendDriverOrder = async (_this, id) => {
    return await _this.http('/galaxyOrder/finishBackendDriverOrder', {id}, "POST");
};

/*
* 查询司机行程录音
 */
export const getDriverRecord = (form) => {
    return http('/galaxyDriverApproveApi/getDriverRecordInfo', form, "POST")
}

/*
* 旅游订单完成订单
 */
export const getFinishTouristOrder = (form) => {
    return http('/galaxyOrder/doFinishTouristOrder', form, "POST")
}

/**
 * 变更订单金额和销售单价
 */
export const updateOrderAmountByOrderId = (form) => http('/galaxyOrder/updateOrderAmountByOrderId', form, 'POST')


/**
 * 变更订单
 */
export const updateOrderTripInfoApi = (form) => http('/galaxyOrder/updateOrderTripInfo', form, 'POST')

/*
* 获取旅游用车订单
 */
export const getIntegrateTripOrderApi = (form) => {
    return http('/galaxyOrder/getIntegrateTripOrder', form, "POST")
}

/*
* 导出旅游用车订单
 */
export const bmsExportIntegrateTripOrderApi = (form) => {
    return http('/galaxyOrder/bmsExportIntegrateTripOrder', form, "POST", true, 'blob')
}

/*
* 变更订单运营商
 */
export const reallocateOperatorApi = (form) => {
    return http('/galaxyOrder/reallocateOperator', form, "POST")
}


/*
* 旅游订单完成订单
 */
export const addOrderVipApi = (form) => {
    return http('/galaxyOrder/addOrderVip', form, "POST")
}

/*
* 旅游订单导入
 */
export const tripOrderImportApi = (form) => {
    return http('/galaxyOrder/importBackendOrder', form, "POST")
}

/*
* 长春用车订单导入
 */
export const importBackendCarOrderApi = (form) => {
    return http('/galaxyOrder/doPostImportBackendCarOrder', form, "POST")
}


export const getOrderGrabSupplierListApi = (form) => http('/galaxyOrderGrab/supplierlist', form, 'POST')

export const supplierGrabOrderApi = (form) => http('/galaxyOrderGrab/supplierGrabOrder', form, 'POST')

/*
* 获取未分配订单
 */
export const getNonDistributionDriverBySupplierApi = () => {
    return http('/galaxyOrder/nonDistributionDriverBySupplier', {}, "POST")
}

/**
 * 开票
 */
export const createInvoiceApi = (data) => http('/galaxyInvoiceApi/createInvoice', data, 'POST')

/**
 * 冲红
 */
export const createRedInvoiceApi = (data) => http('/galaxyInvoiceApi/redInvoice', data, 'POST')

export default {
    orderListHttp,
    getOrderStatusCount,
    OrderRemarkList,
    addOrderRemark,
    distributionGuider,
    touristDistributionGuider,
    distSupplier,
    orderChangeHttp,
    withdrawOrderCancellation,
    withdrawOrderModification,
    rejectCancelApplication,
    acceptOrderCancellation,
    acceptOrderModification,
    cancelOrder,
    getSupplierInfoByOperatorId,
    addSupplier,
    reallocateSupplier,
    operatorRefuse,
    supplierConfirm,
    supplierRefuse,
    operatorShortMess,
    supplierUpdatePrice,
    cancelDistributeGuider,
    getAllSupplier,
    getRestoreAssignSupplier,
    finishBackendDriverOrder,
    getFinishTouristOrder,
    addOrderVipApi,
    tripOrderImportApi,
    importBackendCarOrderApi
}
