const rechargeProducts = () => import('../pages/driverService/rechargeProducts/index');


export default [
    {
        path: '/rechargeProducts',
        name: 'rechargeProducts',
        meta: {
            title: '用车服务管理',
            auth: true
        },
        component: rechargeProducts,
    },
]
