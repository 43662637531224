<template>
    <div></div>
</template>
<script type="text/ecmascript-6">
import {getNonDistributionDriverBySupplierApi} from "@/www/urls/orderUrls";
import moment from "moment";

export default {
    data() {
        return {
            list: []
        }
    },
    mounted() {
        this.notifyModels = {};
        this.order_notice_list = [];
        // if (this.$route.path === '/userCarList' && this.$route.query.orderNo) {
        //     this.order_notice_list.push({
        //         orderNo: this.$route.query.orderNo,
        //         show: false,
        //         time: +new Date()
        //     })
        // }
        window.bus.$on('closeNotice', (orderNo) => {
            this.notifyModels[orderNo] && this.notifyModels[orderNo].close && this.notifyModels[orderNo].close();
            this.order_notice_list.push({
                orderNo,
                show: false,
                time: +new Date()
            })
        })
    },
    methods: {
        startTimer() {
            this.getData();
            this.timer = setInterval(() => {
                this.getData();
            }, 30 * 1000)
        },
        async getData() {
            const ret = await getNonDistributionDriverBySupplierApi();
            if (ret.success && ret.data) {
                this.showNotice(ret.data)
            }
        },
        showNotice(data = []) {
            let list = this.order_notice_list;
            list = list.filter(({orderNo}) => data.find((item) => item.orderNo === orderNo));
            data.forEach((item, i) => {
                const itemOrderNo = item.orderNo;
                const exit = list.length && list.some(({time, orderNo, show}) => {
                    return orderNo === itemOrderNo && (show || moment(Number(time)).add(10, 'm').isAfter(moment()))
                });
                if (!exit) {
                    setTimeout(() => {
                        const notify = this.$notify({
                            title: '警告',
                            dangerouslyUseHTMLString: true,
                            message: `订单<a href="${`#/userCarList?orderNo=${itemOrderNo}`}">${itemOrderNo}</a>待分配司导，请立即操作！`,
                            type: 'warning',
                            duration: 0,
                            onClick: () => {
                                // this.order_notice_list = list.map((it) => ({
                                //     ...it,
                                //     show: itemOrderNo === it.orderNo ? false : it.show,
                                //     time: +new Date()
                                // }))
                                notify.close();
                            },
                            onClose: () => {
                                this.order_notice_list = list.map((it) => ({
                                    ...it,
                                    show: itemOrderNo === it.orderNo ? false : it.show
                                }))
                            }
                        });
                        this.notifyModels[itemOrderNo] = notify;
                    }, 1000 * i)
                    list.push({
                        time: +new Date(),
                        ...item,
                        show: true
                    })
                    this.order_notice_list = list;
                }
            })
        }
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    watch: {
        '$store.state.loginUserInfo': {
            handler(n) {
                if (n && n.loginuserId && n.refType === '1') {
                    this.startTimer()
                } else {
                    if (this.notifyModels) {
                        for (let key in this.notifyModels) {
                            this.notifyModels[key] && this.notifyModels[key].close && this.notifyModels[key].close()
                        }
                    }
                    clearInterval(this.timer)
                }
            },
            immediate: true
        },
    }
}
</script>


<style scoped lang="scss">

</style>
