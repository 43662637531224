export default {
    // 添加tabs
    add_tabs(state, data) {
        const options = state.options;
        if (options && JSON.stringify(options).indexOf(data.name) === -1)
            state.options.splice(1, 0, data);
    },
    // 删除tabs
    delete_tabs(state, route) {
        let index = 0;
        for (let option of state.options) {
            if (option.route === route) {
                break;
            }
            index++;
        }
        this.state.options.splice(index, 1);
    },
    // 设置当前激活的tab
    set_active_index(state, index) {
        this.state.activeIndex = index;
    },
    //改变当前选项的
    change_tabs(state, options) {
        state.options = options
    },
    clear_tabs() {
        this.state.options = []
    },
    addProduct(state, addProduct) {
        state.addProduct = addProduct
    },
    editProduct(state, editProduct) {
        state.editProduct = editProduct
    },
    //框架分离
    outsideFramework(state, bool) {
        state.outsideFramework = bool
    }
}
