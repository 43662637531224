import Vue from 'vue';
import Router from 'vue-router';
import routesDefault from './routes';
import thirdSetMan from './thirdSetMan';
import quotationRouter from './quotationRouter';
import orderManage from './orderManage';
import zslRoutes from './zslRoutes';
import guider from './guider';
import track from './track';
import rechargeProducts from './rechargeProducts';
import vehicleService from './vehicleService';
import member from './member'
import tools from './tools';
import promotionManage from './promotionManage';
import {beforeEach, afterEach} from '../../common/router/index'
import store from '../vuex'
import contentManage from './contentManage';
import messageManage from './message';
import driver from './driver';

/**
 * 处理elementui 菜单和tabs会重复报错的问题
 * @type {Router.push|*}
 */
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router);

let routes = ([...routesDefault,
    ...thirdSetMan,
    ...quotationRouter,
    ...orderManage,
    ...track,
    ...rechargeProducts,
    ...vehicleService,
    ...tools,
    ...guider,
    ...zslRoutes,
    ...member,
    ...promotionManage,
    ...contentManage,
    ...messageManage,
    ...driver
]);
let router = new Router({
    routes
});

//校验当前用户角色是否有访问该路由的权限
function permissionCheck (to) {
    const loginUserInfo = store.state.loginUserInfo;
    const {authorityList} = loginUserInfo;
    if (authorityList && authorityList.nodeList) {
        for (let item of authorityList.nodeList) {
            if (item.nodeList) {
                for (let it of item.nodeList) {
                    if (it.content === to.name)
                        return true;
                }
            }
        }
    }
    return false;
}

router.beforeEach((to, from, next) => {
    //权限验证
    if (!to.meta.free && to.meta.auth && !permissionCheck(to)) {
        return next('/home');
    }
    store.dispatch('outsideFramework', to.meta.outside);
    beforeEach(to, from, next, store)
});

router.afterEach(() => {
    afterEach()
});

export default router;
