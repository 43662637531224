import http from '@/common/js/http'

// 代叫用车产品管理列表
export const rechargeProductInfoList = async (form) => {
    return await http('/galaxyRechargeProductApi/rechargeProductInfoList', form, "POST");
};

// 删除产品
export const rechargeProductInfoDelete = async (id) => {
    return await http(`/galaxyRechargeProductApi/rechargeProductInfodelete/${id}`);
};

// 新增产品
export const createRechargeProductInfo = async (form) => {
    return await http('/galaxyRechargeProductApi/createRechargeProductInfo', form, "POST");
};

// 修改更新产品
export const updateRechargeProductInfo = async (form) => {
    return await http('/galaxyRechargeProductApi/updateRechargeProductInfo', form, "POST");
};

