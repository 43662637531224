import {combinationForm} from "@/common/js/common";
import {
    getQuoteRuleList,
    updateQuoteRuleStatus,
    deleteQuoteRuleProductBatch,
    insertQuoteRuleProductBatch
} from "@/www/urls/quotationUrls";
import {message} from 'element-ui'
import Loading from '@/common/js/loading'

export const initSearchForm = {
    ruleNo: '',  // 规则编号
    ruleName: '',  // 规则名称
    type: '',  // 全部类型
    quoteType: '',
    cityId: '',  // 城市
    supplierId: '',
    operatorId: '',
    quoteChannel: "",
    status: '',
    stationCode: '',
    vehicleType: 0,
    isAcross: undefined,
    charteredCarDuration:undefined
}

const state = () => ({
    list: [],
    page: {
        pageIndex: 1,
        total: 0,
        pageSize: 10
    },
    searchForm: initSearchForm,
    batchShow: false,//是否批量删除
    quoteDetail: {}
});

const mutations = {
    //改变报价列表
    change_quote_list(state, data) {
        state.list = data
    },
    //改变当前页面信息
    change_quote_page(state, data) {
        state.page = {
            ...state.page,
            ...data
        };
    },
    //改变详细
    change_quote_detail(state, data) {
        state.quoteDetail = data;
    },
    //改变请求form
    change_quote_form(state, data) {
        state.searchForm = {
            ...state.searchForm,
            ...data
        };
    },
    //改变列表数据
    change_quotation_list(state, data) {
        state.list = data
    },
    //改变批量删除按钮是否显示
    batch_show(state, data) {
        state.batchShow = data;
        state.list = state.list.map((item) => {
            item.checked = false
            return item
        })
    }

};

const actions = {
    //总部批量新增报价工具
    async add_new_quotation_tool({dispatch}, form) {
        Loading.show()
        const ret = await insertQuoteRuleProductBatch(form);
        Loading.hide();
        if (ret.success && ret.data) {
            message.success('新增成功！');
            dispatch('search_quotation_tool_list', {});
            return true
        } else message.error(ret.errors[0].message)
    },
    //获取报价列表
    async search_quotation_tool_list({commit, state}, {pageIndex, form}) {
        Loading.show();
        commit('change_quote_list', []);
        commit('batch_show', false);

        let searchForm = form || state.searchForm;
        let index = pageIndex || state.pageIndex;
        let data = {
            pageIndex: index,
            pageSize: state.page.pageSize,
            ...combinationForm(searchForm)
        };
        if (data.vehicleType === 0)
            delete data.vehicleType
        let ret = await getQuoteRuleList(data);
        if (ret.success && ret.data) {
            commit('change_quote_list', ret.data.data)
            commit('change_quote_page', {
                pageIndex: index,
                total: ret.data.total
            });
            commit('change_quote_form', searchForm);
        } else message.error(ret.errors[0].message)
        Loading.hide()
    },
    //更新报价状态
    async update_quote_status({commit, state}, form) {
        Loading.show();
        const ret = await updateQuoteRuleStatus(form)
        Loading.hide();
        if (ret.success && ret.data) {
            let list = state.list;
            list.forEach((item) => {
                if (item.id === form.ruleId || (form.ruleIds && form.ruleIds.includes(item.id))) {
                    item.quoteRuleRelProductDto.status = form.status;
                    item.checked = false;
                }
            })
            commit('change_quote_list', list)
            message.success('操作成功！')
            return true;
        } else message.error(ret.errors[0].message)
    },
    //删除报价公式
    async delete_quote_tool({commit, dispatch}, ruleIds) {
        Loading.show();
        const ret = await deleteQuoteRuleProductBatch({ruleIds})
        Loading.hide();
        if (ret.success) {
            if (ret.data) {
                dispatch('search_quotation_tool_list', {})
                commit('batch_show', false);
                message.success('删除成功！');
            } else message.success('删除失败！');
        } else message.error(ret.errors[0].message)
    },
    //批量删除
    delete_quote_list({state, dispatch}) {
        const list = state.list.filter(({checked}) => checked);
        dispatch('delete_quote_tool', list.map(({id}) => id))
    }
};

export default {
    state,
    mutations,
    actions
}

